import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Tooltip from "reactstrap/lib/Tooltip";
import { useOfficeDetail } from "repositories/office-repository";
import useHandleApiError from "hooks/useHandleApiError";

function StaffCard(props) {
  const [pmsConnectTooltip, setPmsConnectTooltip] = useState(false);

  const {
    data: {
      profilePic,
      firstName,
      lastName,
      designation,
      timeSheet,
      type,
      isAdmin,
      staffRoleId,
      isActive,
      inviteId,
      sikkaProviderId,
    },
    t,
  } = props;
  let typeData = null;

  const {
    data: officeDetail,
    error: officeDetailError,
    isLoading: loadingOfficeDetails,
    isFetching: fetchingOfficeDetails,
  } = useOfficeDetail(props.OfficeId);

  useHandleApiError(
    loadingOfficeDetails,
    fetchingOfficeDetails,
    officeDetailError
  );

  if (type === 1) {
    typeData = (
      <div className="process">
        <img
          src={require("assets/images/process-icon.svg").default}
          alt="staff"
        />
        <span>
          <strong>{timeSheet || "-"}</strong> ({t("accountOwner.thisMonth")})
        </span>
      </div>
    );
  }

  if (type === 3) {
    typeData = (
      <div className="process-pending">
        <img
          src={require("assets/images/invitation-pending.svg").default}
          alt="staff"
        />
        <span>{t("accountOwner.invitationPending")}</span>
      </div>
    );
  }

  if (type === 4) {
    typeData = (
      <div className="process-decline">
        <img
          src={require("assets/images/invitation-declined.svg").default}
          alt="staff"
        />
        <span>{t("accountOwner.invitationDeclined")}</span>
      </div>
    );
  }

  if (!isActive && !inviteId) {
    typeData = (
      <div className="process-pending">
        <img
          src={require("assets/images/invitation-pending.svg").default}
          alt="staff"
        />
        <span>{t("accountOwner.deactivated")}</span>
      </div>
    );
  }

  return (
    <Link
      to={{
        pathname: "/staff-detail",
        state: {
          detail: props.data,
          OfficeId: props.OfficeId,
          officeData: props?.location?.state?.officeData
        },
      }}
    >
      <div
        className={`staff-card ${type === 3 ? "pending-card" : ""} ${
          type === 4 ? "decline-card" : ""
        } ${type === 2 ? "deactivated-card" : ""}`}
      >
        {sikkaProviderId && (
          <div className=" staff_office_tooltip">
            <div className="staff_tooltip_dentrix_wrapper">
              <img
                className="cursor-pointer"
                onClick={() => {
                  setPmsConnectTooltip(!pmsConnectTooltip);
                }}
                id="PmsConnectTooltip"
                src={require("assets/images/info_black-tooltip.svg").default}
                alt="icon"
              />
              <Tooltip
                className="new-item-card-catalogue-tooltip"
                isOpen={pmsConnectTooltip}
                placement="top"
                target="PmsConnectTooltip"
                toggle={() => {
                  setPmsConnectTooltip(!pmsConnectTooltip);
                }}
              >
                {t("pmsMembers.staffSyncedMsg", {
                  pmsName: officeDetail?.practiceManagementSystemName,
                })}
              </Tooltip>
              <span className="staff_dentrix_text">
                {officeDetail?.practiceManagementSystemName}
              </span>
            </div>
          </div>
        )}
        <div
          className={`${
            sikkaProviderId ? "card-content" : "card-content_not_present"
          }`}
        >
          <div className="staff-img mb-4">
            <div className="_img">
              {profilePic ? (
                <img src={profilePic} alt="staff" />
              ) : (
                <img
                  src={require("assets/images/staff-default.svg").default}
                  alt="staff"
                />
              )}
            </div>
            <span className="ico">
              {staffRoleId === 2 ? (
                <img
                  src={require("assets/images/star.svg").default}
                  alt="staff"
                />
              ) : null}
              {staffRoleId === 1 ? (
                <img
                  src={require("assets/images/star-pending.svg").default}
                  alt="staff"
                />
              ) : null}
            </span>
          </div>
          <h4>
            {firstName} {lastName}
          </h4>
          <div className="profile">
            <img
              src={require("assets/images/staff-icon.svg").default}
              alt="staff"
            />
            <span>{designation}</span>
          </div>
          {typeData}
        </div>
        {isAdmin ? (
          <div className="admin-access-ico">
            <img
              src={require("assets/images/admin-access.svg").default}
              alt="icon"
            />
          </div>
        ) : null}
      </div>
    </Link>
  );
}

export default withTranslation()(StaffCard);
