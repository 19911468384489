import React from "react";
import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import Text from "components/Text";
import { Modal, ModalBody } from "reactstrap";
import "./../../../FamilyMembers/familyMembers.scss";
import crossIcon from "../../../../../assets/images/cross.svg";
import editProfileWarningIcon from "./../../../../../assets/images/info-icon.png";

const FamilyModal = ({
  t,
  isFamilyModalOpen,
  setIsFamilyModalOpen,
  title,
  subTitle1,
  subTitle2,
  leftBtnText,
  rightBtnText,
  onConfirm,
  closeOnCross,
  note,
  hideCrossIcon,
}) => {
  const closeFamilyModal = () => setIsFamilyModalOpen(false);

  return (
    <Modal
      isOpen={isFamilyModalOpen}
      toggle={() => {closeOnCross ? closeOnCross() : closeFamilyModal()}}
      className="modal-dialog-centered delete-family-modal"
      modalClassName="custom-modal"
    >
     {!hideCrossIcon && 
      <span
        className="close-btn"
        onClick={() => {
          closeOnCross ? closeOnCross() : closeFamilyModal();
        }}
      >
        <img src={crossIcon} alt="close" />
      </span>
     } 
      <ModalBody>
        <Text size="25px" marginBottom="4px" weight="500" color="#111b45">
          <span className="modal-title-25">{title}</span>
        </Text>
        <Text
          size="16px"
          weight="300"
          color=" #535b5f"
          className="staff_subtitle_two"
        >
          {subTitle1}
        </Text>
        {subTitle2 && (
          <Text
            size="16px"
            marginBottom="40px"
            weight="300"
            color=" #535b5f"
            marginTop="20px"
          >
            {subTitle2}
          </Text>
        )}

        {note && (
          <div className="page-warning-container container mt-3">
            <div className="page-warning-bg">
              <img
                className="page-warning-icon"
                src={editProfileWarningIcon}
                alt=""
              />
              <div className="page-warning-text">{note}</div>
            </div>
          </div>
        )}

        <div className="d-md-flex btn-box faimly_modal_popup_btn_container">
          <button
            className="button button-round button-shadow w-sm-100 mr-md-2"
            title={leftBtnText}
            onClick={onConfirm}
          >
            {leftBtnText}
          </button>
          <button
            className="button button-round button-border btn-mobile-link button-dark "
            title={rightBtnText}
            onClick={closeFamilyModal}
          >
            {rightBtnText}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(FamilyModal);
