import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import crossIcon from "../../assets/images/cross.svg";
import Text from "components/Text";
import styles from "./CustomModal.module.scss";
import Input from "components/Input";
import { cloneDeep } from "lodash";
import { validateEmail } from "utils";
import Helper from "utils/helper";
import toast from "react-hot-toast";

/**
 *
 * @param {{
 *isOpen: Boolean,
 *setIsOpen: Function,
 *onConfirm: Function,
 *onBeforeClose: Function | f => f,
 *title: String,
 *subTitle1: String,
 *subTitle2: String | '',
 *leftBtnText: String | '',
 *rightBtnText: String | '',
 *}} props
 * @returns
 */
function CustomFormModal(props) {
  const {
    isOpen,
    setIsOpen,
    onConfirm,
    modalData,
    title,
    leftBtnText = "",
    rightBtnText = "",
    t,
  } = props;
  const closeModal = () => {
    setIsOpen(false);
  };

  const [formFields, setformFields] = useState({
    contactNumber: modalData?.contactNumber || "",
    emailAddress: modalData?.sikkaEmailId || "",
  });
  const [errors, setErrors] = useState({});

  /* extract values from form object */
  const { emailAddress, contactNumber } = formFields;

  /* error messages */
  const errorMessage = {
    emailAddress: t("form.errors.emptyField", {
      field: t("familyMembers.emailAddress"),
    }),
    contactNumber: t("form.errors.emptyField", {
      field: t("form.fields.contactNumber"),
    }),
  };

  const isValid = () => {
    const errorCopy = {};
    let isValidField = true;

    if (emailAddress && !validateEmail(emailAddress)) {
      
      errorCopy.emailAddress = t("form.errors.invalidValue", {
        field: t("form.fields.emailAddress"),
      });
      isValidField = false;
    }

    if (!emailAddress.trim().length && !contactNumber.trim().length) {
      toast.error("Atleast enter one field");
      isValidField = false;
    }

    setErrors({ ...errorCopy });
    return isValidField;
  };

  const handleEmailChange = (event) => {
    const errorCopy = cloneDeep(errors);
    const { name, value } = event.target;

    if (!value.trim().length) {
      errorCopy.emailAddress = errorMessage.emailAddress;
    } else {
      if (validateEmail(value)) {
        delete errorCopy.emailAddress;
      } else {
        errorCopy.emailAddress = t("form.errors.invalidValue", {
          field: t("form.fields.emailAddress"),
        });
      }
    }

    setformFields((prevProps) => ({ ...prevProps, [name]: value }));
    setErrors(errorCopy);
  };

  const handleChange = (event) => {
    const errorCopy = cloneDeep(errors);
    const { name, value } = event.target;

    if (!value.trim().length) {
      if (Helper.validateNumber(value)) {
        delete errorCopy.contactNumber;
      } else {
        errorCopy.contactNumber = t("form.errors.invalidValue", {
          field: t("form.fields.contactNumber"),
        });
      }
    }

    setformFields((prevProps) => ({ ...prevProps, [name]: value }));
    setErrors(errorCopy);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        className={"modal-dialog-centered " + styles["custom-modal-dialog"]}
        modalClassName="custom-modal"
      >
        <span className="close-btn" onClick={closeModal}>
          <img src={crossIcon} alt="close" />
        </span>

        <ModalBody>
          <Text size="25px" weight="500" color="#111b45">
            {title}
          </Text>
          <Text marginBottom="10px" size="13px">
            {t("pmsMembers.addEmailforSyncAppointment")}
          </Text>

          <div className="row">
            <div className="col-md-6">
              <Input
                Title={t("familyMembers.emailAddress")}
                Type="email"
                Name={"emailAddress"}
                Placeholder={t("form.placeholder1", {
                  field: t("familyMembers.emailAddress"),
                })}
                Disabled={modalData?.sikkaEmailId}
                Error={errors.emailAddress}
                Value={emailAddress || ""}
                HandleChange={handleEmailChange}
                MaxLength={235}
              />
            </div>
            <div className="col-md-6">
              <Input
                Title={t("form.fields.contactNumber")}
                Type="email"
                Name={"contactNumber"}
                Placeholder={t("form.placeholder1", {
                  field: t("form.fields.contactNumber"),
                })}
                HandleChange={handleChange}
                Error={errors.contactNumber}
                Value={contactNumber || ""}
              />
            </div>
          </div>
          {!!leftBtnText && (
            <button
              className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
              onClick={() => {
                if (!isValid()) return;
                onConfirm(emailAddress, contactNumber);
              }}
              title={leftBtnText}
            >
              {leftBtnText}
            </button>
          )}
          {!!rightBtnText && (
            <button
              className="button button-round button-border button-dark btn-mobile-link"
              onClick={closeModal}
              title={rightBtnText}
            >
              {rightBtnText}
            </button>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTranslation()(CustomFormModal);
