import React, { useRef, useEffect } from 'react';

function BrandingMapModal({ address, latitude, longitude }) {
  const mapRef = useRef(null);

  useEffect(() => {
    const mapOptions = {
      zoom: 15,
      center: { lat: latitude, lng: longitude }
    };
    const map = new window.google.maps.Map(mapRef.current, mapOptions);

    new window.google.maps.Marker({
      map: map,
      position: { lat: latitude, lng: longitude }
    });
  }, [address, latitude, longitude]);

  return <div ref={mapRef} style={{ width: '100%', height: '400px' }}></div>;
}

export default BrandingMapModal;