import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import appointmentIcon from "./../../assets/images/confirmation-icon.svg";
import { useParams } from "react-router-dom";
import { decodeId, handleError, handleSuccess } from "utils";
import { getAppointmentConfirmation } from "repositories/appointment-repository";
import Loader from "components/Loader";
import constants from "../../constants";

let isLoaded = false;
const CustomAppointmentConfirmationPage = ({ t }) => {
  let { appointment_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationsMessage, setConfirmationsMessage] = useState("Appointment is Confirmed");
  const confirmationThrough = constants?.confirmedThrough?.email;
  
  appointment_id = decodeId(appointment_id)

  useEffect(() => {
    if (!isLoaded) {
      onAppointmentConfirmation();
    }
  }, [])
  

  const onAppointmentConfirmation = async () => {
    
    setIsLoading(true);
    isLoaded = true
    try {
      let res = await getAppointmentConfirmation(appointment_id, confirmationThrough);
      setConfirmationsMessage(res.message);
      handleSuccess(res.message);
    } catch (error) {
     
      handleError(error.message);
    } finally{

      setIsLoading(false);
    }
  };

  return (
    <div className="page-error-block">
      {isLoading && <Loader />}
      <div className="container">
        <div className="page-error-container">
          <div className="page404Wrapper">
            <img src={appointmentIcon} alt="appointmentIcon" />
            <h5>{confirmationsMessage}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CustomAppointmentConfirmationPage);
