import { SendBirdProvider } from "@sendbird/uikit-react";

const withMessageCount = (Component) => {
  return (props) => {
    const profile = props.profile || {};
   
      return (
        <SendBirdProvider
          appId={process.env.REACT_APP_SENDBIRD_APP_ID}
          userId={`${profile.id}`}
        >
          <Component {...props} />
        </SendBirdProvider>
      );

  };
};

export default withMessageCount;
