import React from "react";
import Page from "components/Page";
import { Card } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import styles from "./accountpreferences.module.scss";
import ChatIcon from "../../assets/images/MulticolorChatIcon.svg";
import constants from "./../../constants";
import { withTranslation } from "react-i18next";

const AccountPrefences = ({ history, t }) => {
  const accountList = [
    {
      id: 1,
      chatImg: ChatIcon,
      accountName: t("accountPreferences.messageTemplates"),
      link: constants.routes.accountPreference.messageTemplates,
    },
  ];

  const goBack = () => history.push(constants.routes.accountOwner.offices);

  return (
    <>
      <Page onBack={goBack} className={styles["global-main-page"]}>
        <div>
          <div className={styles["account-preferences-heading"]}>
            <p className={styles["mobile-text-size"] + " " + "mb-0"}>
              {t("accountPreferences.accountPreferences")}
            </p>
            <p className="pms_subtitle">{t("accountPreferences.patientSchedulingTemplates")}</p>
          </div>

          <div className={styles["manage-card-container"] + " " + "d-flex"}>
            {accountList.map((item) => (
              <Link
                className={styles["card-con"] + " " + "form-wrapper"}
                to={item.link}
                key={item.id}
              >
                <Card
                  className={
                    styles["account-preference-card"] +
                    " " +
                    "d-flex align-items-center flex-row w-100"
                  }
                >
                  <div className={styles["account-preference-logo"]}>
                    <img
                      key={item.id}
                      src={item.chatImg}
                      alt="message template icon"
                    />
                  </div>
                  <div
                    className={styles["account-preference-text"]}
                    key={item.id}
                  >
                    {item.accountName}
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </Page>
    </>
  );
};

export default withTranslation()(AccountPrefences);
