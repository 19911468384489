import React, { useEffect, useState } from "react";
import Page from "components/Page";
import { Col, Row } from "reactstrap";
import styles from "./OfficesListForPms.module.scss";
import { withTranslation } from "react-i18next";
import constants from "./../../constants";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useOfficeListingBySikkaConnectedStatus } from "repositories/office-repository";
import useHandleApiError from "hooks/useHandleApiError";
import Loader from "components/Loader";
import { uniqBy } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import Empty from "components/Empty";

const PAGE_SIZE = 12;
function OfficesListForPms({ history, location, t }) {
  const profile = useSelector((state) => state.userProfile.profile);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageData, setPageData] = useState({
    offices: [],
    totalItems: 0,
    totalPages: 1,
  });
  const { offices, totalItems, totalPages } = pageData;

  const enabled = !!(profile && profile.id);

  const {
    isLoading,
    isFetching,
    error,
    data: activeOffices,
  } = useOfficeListingBySikkaConnectedStatus(profile?.id, pageNumber, PAGE_SIZE, false, { enabled });

  const goBack = () => history.push(constants.routes.accountOwner.offices);
  let officeName = null;
  
  if (location?.state?.officeName) {
    officeName = location.state.officeName;
  }

  /* use to handle API errors */
  useHandleApiError(isLoading, isFetching, error);

  /**
   * @event: [useEffect]
   * @description: {
   *  call family members listing and set the total pages and the total items
   * }
   */
  useEffect(() => {
      window.scrollTo(0, 0);
      if (!isLoading && activeOffices && activeOffices.items) {
        
      setPageData((prev) => {
        return {
          totalItems: activeOffices?.pagination?.totalItems,
          totalPages: activeOffices?.pagination?.totalPages,
          offices: uniqBy(
            [...(pageNumber == 1 ? [] : prev.offices), ...activeOffices.items],
            "id"
          ).filter((val) => !val?.isConnectedWithSikka && val ),
        };
      });
    }
  }, [isLoading, activeOffices, pageNumber]);

  const renderheader = () => {
    return (
      <div className="mb-4">
        <h2 className="title pms_heading">
          {t("navbar.integrateOfficeWithPms")}
        </h2>
        <p className="pms_subtitle">{t("pmsMembers.selectOffice")}</p>
      </div>
    );
  };

  const handleOffice = (officeId) => {
    history.push("/editOffice", { officeId: officeId, from: "offices-list", showSubHeading: true, });
  };

  return (
    <Page onBack={goBack} className={styles["page"]}>
      {renderheader()}
      <InfiniteScroll
        dataLength={offices.length}
        hasMore={offices.length < totalItems}
        next={() => pageNumber < totalPages && setPageNumber((v) => v + 1)}
      >
        {isLoading && <Loader />}
        <Row>
          {offices &&
            offices?.length > 0 &&
            offices.map((office) => (
              <Col lg="4" key={office?.id}>
                <Card
                  redirectTo={() => handleOffice(office?.id)}
                  icon={
                    office?.officeImage && office?.officeImage !== null
                      ? office?.officeImage
                      : require("assets/images/default-image.svg").default
                  }
                  title={office?.name}
                  officeName={officeName}
                />
              </Col>
            ))}
        </Row>
      </InfiniteScroll>
      {offices && offices?.length === 0 && (
        <Empty Message={t("pmsMembers.noRecord")} />
      )}
    </Page>
  );
}

function Card({ icon, title, redirectTo }) {
  return (
    <motion.div
      whileHover={{ scale: constants.animation.hoverScale }}
      whileTap={{ scale: constants.animation.hoverScale }}
    >
      <span onClick={redirectTo} className="pointer">
        <div className={styles["card"]}>
          <img className={`${styles["icon"]} mr-2`} src={icon} alt="icon" />
          <h4>{title}</h4>
        </div>
      </span>
    </motion.div>
  );
}

export default withTranslation()(OfficesListForPms);
