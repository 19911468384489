import React from "react";
import { Modal, ModalBody } from "reactstrap";
import OnePlus from "../../../assets/images/onePlus.svg";
import CalendarIcon from "../../../assets/images/calendar-add.svg";
import MapIcon from "../../../assets/images/new_map.svg";
import Facebook from "../../../assets/images/new_facebook.svg";
import Instagram from "../../../assets/images/new_instagram.svg";
import LinkedIn from "../../../assets/images/new_linkedin.svg";
import "./EmailTemplate.scss";
import { withTranslation } from "react-i18next";
import BrandingMapModal from "../components/BrandingMapModal";

const BrandEmailTemplate = ({
  isModalOpen,
  closeModal,
  t,
  modalContent,
  officeLogo,
  previewType,
}) => {
  const {
    emailBrandingOfficeName,
    emailBrandingOfficeAddress,
    emailBrandingOfficeEmail,
    emailBrandingOfficeContactNumber,
    officeWebSiteLink,
    officeInstagramLink,
    officeLinkedinLink,
    officeFacebookLink,
    isLocationEnabledForPatientAppointmentEmailReminder,
    isCalendarEventEnabledForPatientAppointmentEmailReminder,
    isLocationEnabledForPatientAppointmentConfirmationEmail,
    isCalendarEventEnabledForPatientAppointmentConfirmationEmail,
    isLocationEnableForPatientEmailRecallRemider,
    isLocationEnableForSystemEmailToPatientOwnerAndStaff,
    emailBrandingOfficeLatitude,
    emailBrandingOfficeLongitude,
  } = modalContent;

  return (
    <Modal isOpen={isModalOpen} toggle={closeModal}>
      <div className="modal-header"></div>
      <ModalBody className="brand-email-template-container">
        <div className="modal_body_topbar">
          <div className="left_topbar_wrapper">
            {officeLogo ? (
              <img src={officeLogo} alt="OnePlus" />
            ) : (
              <img src={OnePlus} alt="OnePlus" />
            )}

            <div className="email_address">
              {emailBrandingOfficeAddress || "[OFFICE_ADDRESS]"}
            </div>
          </div>
          <div className="lifeline_wrapper">
            <h5 className="lifeline_text">
              {emailBrandingOfficeName || "[OFFICE_NAME]"}
            </h5>
            <div className="contact_text">
              {emailBrandingOfficeContactNumber || "[OFFICE_CONTACT_NUMBER]"}
            </div>
            <div className="visit_website">
              <span>{t("officeBranding.emailUs")}: </span>
              {emailBrandingOfficeEmail || "[OFFICE_EMAIL]"}
            </div>
            <div className="visit_website">
              <span>{t("officeBranding.visitOurWebsite")}: </span>
              {officeWebSiteLink || "[WEBSITE_LINK]"}
            </div>
          </div>
        </div>
        <div className="modal_bottom_body">
          <div className="modal_middle_body">
            <>
              <div className="general_text">
                {t("officeBranding.yourAppointmentIsOn")}:
              </div>
              <h4 className="date_text">Apr 15, Mon at 10:30 AM</h4>
              <div className="user_name_text">Hi Daniel,</div>
              <div className="general_text mt-3">
                We are looking forward to seeing you for your dental appointment
                on{" "}
                <span className="appointent_date_text">
                  Apr 15, Mon at 10:30AM
                </span>{" "}
                for <span className="appointent_date_text">Daniel Smith</span>.
              </div>
              <p className="general_text mt-2">
                This time has been set aside especially for you. To avoid
                incurring missed appointment charges, please contact our office
                at [office number] as soon as possible if you need to
                reschedule. Please note we have a 48 business hour cancellation
                policy.
              </p>
              <div className="general_text">
                <div>Regards,</div>
                <div>{emailBrandingOfficeName || "[OFFICE_NAME]"}</div>
                <div>
                  <span>{t("officeBranding.tel")}: </span>{" "}
                  {emailBrandingOfficeContactNumber ||
                    "[OFFICE_CONTACT_NUMBER]"}
                </div>
                <div>
                  <span>{t("officeBranding.address")}:</span>{" "}
                  {emailBrandingOfficeAddress || "[OFFICE_ADDRESS]"}
                </div>
              </div>
            </>
            {((previewType === "patientAppointmentReminder" &&
              isCalendarEventEnabledForPatientAppointmentEmailReminder) ||
              (previewType === "patientConfirmationEmail" &&
                isCalendarEventEnabledForPatientAppointmentConfirmationEmail)) && (
              <div>
                <div className="calendar_text_wrapper">
                  <div>
                    <h5>{t("officeBranding.addToCalendar")}</h5>
                    <div>{t("officeBranding.appointmentToCalendar")}</div>
                  </div>
                  <img src={CalendarIcon} alt="CalendarIcon" />
                </div>
                <div className="add_to_calendar">
                  <p>{t("officeBranding.addToCalendar")}</p>
                </div>
              </div>
            )}
            <div>
              {((previewType === "systemEmailsToPateint" &&
                isLocationEnableForSystemEmailToPatientOwnerAndStaff) ||
                (previewType === "patientAppointmentReminder" &&
                  isLocationEnabledForPatientAppointmentEmailReminder) ||
                (previewType === "patientRecallReminder" &&
                  isLocationEnableForPatientEmailRecallRemider) ||
                (previewType === "patientConfirmationEmail" &&
                  isLocationEnabledForPatientAppointmentConfirmationEmail)) && (
                <>
                  <div className="calendar_text_wrapper">
                    <div>
                      <h5>{t("officeBranding.ourLocation")}</h5>
                      <div>{emailBrandingOfficeAddress}</div>
                    </div>
                    <img src={MapIcon} alt="MapIcon" />
                  </div>
                  <BrandingMapModal
                    address={emailBrandingOfficeAddress}
                    latitude={emailBrandingOfficeLatitude}
                    longitude={emailBrandingOfficeLongitude}
                  />
                </>
              )}
              <div className="subscribe_text">
                <a href={officeWebSiteLink} target="_blank" rel="noreferrer">
                  {officeWebSiteLink}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="social_media_wrapper">
          {officeFacebookLink && (
            <a href={officeFacebookLink} target="_blank" rel="noreferrer">
              <img src={Facebook} alt="Facebook" />
            </a>
          )}

          {officeInstagramLink && (
            <a href={officeInstagramLink} target="_blank" rel="noreferrer">
              <img src={Instagram} alt="Instagram" />
            </a>
          )}

          {officeLinkedinLink && (
            <a href={officeLinkedinLink} target="_blank" rel="noreferrer">
              <img src={LinkedIn} alt="LinkedIn" />
            </a>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default withTranslation()(BrandEmailTemplate);
