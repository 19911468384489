import React from 'react'
import OdaIcon from '../../../assets/images/landing-pages/oda-event-icon.png'
import styles from '../style.module.scss'
import Text from 'components/Text'
export default function LatestCard() {
    return (
        <div className={styles["latest-card-box"]}>
            <div className={styles["latest-event-icon"]}>
                <img src={OdaIcon} alt='event-icon' />
            </div>
            <div>
                <Text className={styles['latest-event-heading']}>ODA (Ontario Dental Associates)</Text>
                <Text className={styles["asm-subheading"] + " " + "text-center"}>ASM24 (Annual Spring Meeting)</Text>
            </div>

            <div className={styles['latest-event-detail']}>
                <div className='d-flex align-items-center'>
                    <div className={styles['custom-dot']}></div>
                    <span className={styles['event-date']}>April 18th-20th, 2024</span>
                </div>
                <div className='d-flex align-items-center'>
                    <div className={styles['custom-dot']}></div>
                    <span className={styles['booth-id']}>Booth 233</span>
                </div>
                <div className='d-flex align-items-center'>
                    <div className={styles['custom-dot']}></div>
                    <span className={styles['event-location']}>Toronto, Canada</span>
                </div>
            </div>
            <div className={styles['event-button-box']}>
                <a href="https://meetings.hubspot.com/madjid-rassamanesh/oda-asm24" target='blank' className={styles['latest-custom-events-button']}>
                    <button className="button button-round button-shadow" >Book an Onsite Demo</button>
                </a>
                <a href="https://asm.oda.ca/" target='blank' className={styles['view-event-detail']}>
                    View Details
                </a>
            </div>

        </div>
    )
}
