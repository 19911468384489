import React, { useMemo } from "react";
import ReactDom from "react-dom";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import mapMarker from "assets/images/map-marker.svg";
import MapPinPopup from "./MapPinPopup";
import { useSelector } from "react-redux";
import BookAppointmentModal from "./BookAppointmentModal";
import useHandleApiError from "hooks/useHandleApiError";
import { useGetMembersForBooking } from "repositories/family-member-repository";
import { useState } from "react";

const DoctorsMap = (props) => {
  const {
    google,
    center,
    items,
    activeMarker,
    setActiveMarker,
    clickHandlers,
  } = props;

  const handleInfoPopupClose = () => {
    setActiveMarker(null);
  };
  const profile = useSelector((state) => state.userProfile.profile);

  const PAGE_SIZE = 2;
  const memberPageNumber = 1;
  const {
    data,
    error: isError,
    isLoading,
    isFetching,
  } = useGetMembersForBooking(memberPageNumber, PAGE_SIZE, {
    enabled: !!profile?.id,
  });

  useHandleApiError(isLoading, isFetching, isError);

  const [isBookAppointmentModalOpen, setIsBookAppointmentModalOpen] =
    useState(false);
  const [appointmentType, setAppointmentType] = useState("");

  const openBookAppointmentPopUp = (type) => {
    setIsBookAppointmentModalOpen(true);
    setAppointmentType(type);
  };

  const { handleBookAppointment, handleRequestAppointment } =
  clickHandlers;

  const onInfoWindowOpen = () => {
    const infoContent = (
      <MapPinPopup
        activeMarker={activeMarker}
        handleInfoPopupClose={handleInfoPopupClose}
        clickHandlers={clickHandlers}
        profile={profile}
        openBookAppointmentPopUp={openBookAppointmentPopUp}
        data={data}
      />
    );
    ReactDom.render(infoContent, document.getElementById("map-info-popover"));
  };

  const markerContent = useMemo(() => {
    return items.map((marker, index) => (
      <Marker
        key={`map_marker_${index}`}
        animation={google?.maps?.Animation?.DROP || 2}
        position={{
          lat: marker?.location?.lat,
          lng: marker?.location?.lng,
        }}
        onClick={() => {
          setActiveMarker(marker);
        }}
        icon={mapMarker}
      />
    ));
    //eslint-disable-next-line
  }, [items]);

  const mapPopupContent = useMemo(() => {
    return (
      <InfoWindow
        position={activeMarker?.location}
        visible={!!activeMarker}
        maxWidth={"100%"}
        onOpen={onInfoWindowOpen}
        pixelOffset={google?.maps ? new google.maps.Size(0, -47) : null}
      >
        <div id="map-info-popover" />
      </InfoWindow>
    );
    //eslint-disable-next-line
  }, [activeMarker]);

  return (
    <>
      <Map
        google={google}
        disableDefaultUI={true}
        initialCenter={center || { lat: 0, lng: 0 }}
        zoomControl={true}
        scaleControl={true}
        zoomControlOptions={{
          position: google?.maps?.ControlPosition?.TOP_LEFT,
        }}
        center={center}
        zoom={14}
      >
        {markerContent}
        {mapPopupContent}
      </Map>
      {isBookAppointmentModalOpen && (
        <BookAppointmentModal
          isBookAppointmentModalOpen={isBookAppointmentModalOpen}
          setIsBookAppointmentModalOpen={setIsBookAppointmentModalOpen}
          loggedInUserId={profile?.id}
          handleMemberClick={(memberId) => {
            appointmentType === "bookAppointment"
              ? handleBookAppointment(activeMarker, memberId)
              : handleRequestAppointment(activeMarker, memberId);
          }}
        />
      )}
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(DoctorsMap);