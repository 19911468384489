import React from "react";
import styles from "./Offices.module.scss";
import { withTranslation } from "react-i18next";
import Page from "components/Page";
import OfficeGrid from "./OfficeGrid";
import { decodeId, encodeId } from "utils";
import qs from "query-string";
import constants from "./../../../constants";

function Offices({ match, location, history, t }) {
  let doctorId = null;
  try {
    doctorId = decodeId(match.params.doctorId);
  } catch (e) {
    history.push(constants.routes.doctors);
    return null;
  }

  if (
    isNaN(doctorId) ||
    !location.state ||
    !location.state.officeId ||
    !location.state.doctorName
  ) {
    history.push(constants.routes.doctors);
    return null;
  }

  const { officeId, doctorName, memberId, recallDate, recallId, patientId } = location.state;

  const goBack = () => {
    const searchParams = {
      doctorId: encodeId(doctorId),
      officeId: encodeId(officeId),
    };

    if (memberId) {
      searchParams.memberId = encodeId(memberId);
    }

    if (recallDate) {
      searchParams.recallDate = encodeId(recallDate);
    }

    if (recallId) {
      searchParams.recallId = encodeId(recallId);
    }

    if (patientId) {
      searchParams.patientId = encodeId(patientId);
    }

    history.push({
      pathname: location?.state?.backTo
        ? location?.state?.backTo
        : constants.routes.doctor,
      search: qs.stringify(searchParams),
    });
  };

  return (
    <Page
      className={styles["doctor-office-page"]}
      title={t("patient.officesPageTitle", { doctorName })}
      onBack={goBack}
    >
      <OfficeGrid doctorId={doctorId} memberId={memberId} recallDate={recallDate} recallId={recallId} patientId={patientId} />
    </Page>
  );
}

export default withTranslation()(Offices);
