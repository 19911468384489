import React, { Fragment, useState } from "react";
import { withTranslation } from "react-i18next";
import Empty from "components/Empty";
import Page from "components/Page";
import Loader from "components/Loader";
import { Link } from "react-router-dom";
import { encodeId } from "utils";
import useToGetPmsDoctorsList from "../../hooks/useToGetPmsDoctorsList";
import styles from "../../pms.module.scss";
import SortDown from "../../../../../assets/images/pms-icons/sort_down.svg";
import SortUp from "../../../../../assets/images/pms-icons/sort_up.svg";
import Table from "components/table";
import "../../pms_new.scss";
import CommonSearch from "components/CommonSearch";

const Doctors = ({ t, officeId, tabStatus, IsForDoctor, location }) => {
  const pageSize = 10;
  const { doctorData, methods } = useToGetPmsDoctorsList({
    t,
    tabStatus,
    officeId,
    IsForDoctor,
    pageSize,
  });

  const [sort, setSort] = useState({});

  const { pmsDoctorList, isLoading, totalItems, pageNumber, searchText } =
    doctorData;
  const { setPageNumber, handleSearch } = methods;

  const columns = [
    {
      attrs: { datatitle: t("pmsMembers.firstName") },
      dataField: "counter",
      text: (
        <Fragment>
          <span>
            <label className="mb-0">
              <span className="py-1 pms_table_heading">
                {" "}
                {t("pmsMembers.firstName")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span className="pms_table_data">{row?.firstName || "--"}</span>
        </Fragment>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span>
              {" "}
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },
    {
      attrs: { datatitle: t("pmsMembers.lastName") },
      dataField: "lastName",
      text: (
        <Fragment>
          <span>
            <label className="">
              <span className="py-1 pms_table_heading">
                {" "}
                {t("pmsMembers.lastName")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span className="pms_table_data">{row?.lastName || "--"}</span>
        </Fragment>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },
    {
      attrs: { datatitle: t("pmsMembers.emailAddress") },
      dataField: "emailAddress",
      text: (
        <Fragment>
          <span>
            <label className="">
              <span className="py-1 pms_table_heading">
                {t("pmsMembers.emailAddress")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span className="pms_table_data">{row.sikkaEmailId || "--"}</span>
        </Fragment>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },
    {
      attrs: { datatitle: t("pmsMembers.action") },
      dataField: "action",
      text: (
        <Fragment>
          <div className="">
            <span>
              <label className="">
                <span className="py-1 pms_table_heading">
                  {" "}
                  {t("pmsMembers.action")}
                </span>
              </label>
            </span>
          </div>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <div className="">
            <div className="custom-staff-name">
              <Link
                to={{
                  pathname: `/AddStaff/${encodeId(officeId)}`,
                  state: row,
                }}
              >
                <span className="pms_sent_invite">
                  {t("pmsMembers.sendInvite")}
                </span>
              </Link>
            </div>
          </div>
        </Fragment>
      ),
    },
  ];

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (type === "sort") {
      setSort({ sortField, sortOrder });
    }
  };

  let updatedList = pmsDoctorList;
  if (sort && sort.sortField) {
    const { sortField, sortOrder } = sort;
    updatedList = updatedList.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }

  const renderListing = () => (
    <div
      className={`table-td-last-50 timesheet-table shadow-responsive ${styles["pms_doctors_table"]}`}
    >
      <div className="d-flex justify-content-end">

      <CommonSearch
        Classes="pb-3 w50"
        Placeholder={t("pmsMembers.searchByDoctorName")}
        HandleChange={(e) => handleSearch(e)}
        value={searchText}
      />
      </div>
      {!!pmsDoctorList?.length ? (
        <Table
          keyField="id"
          data={updatedList}
          columns={columns}
          defaultSortDirection={"desc"}
          handlePagination={(e) => setPageNumber(e)}
          pageNumber={pageNumber}
          totalItems={totalItems}
          pageSize={pageSize}
          onTableChange={handleTableChange}
        />
      ) : (
        <Empty Message={t("noRecordFound")} />
      )}
    </div>
  );

  return (
    <Page className={"staff-listing-timesheet pt-1"}>
      {isLoading && <Loader />}
      {renderListing()}
    </Page>
  );
};

export default withTranslation()(Doctors);
