import axios from "axios";
import { Config } from "../config";
import { useQuery } from "react-query";

const baseUrl = Config.serviceUrls.officeBaseUrl;
const patientSchedulingBaseUrl = Config.serviceUrls.patientSchedulingBaseUrl;

const getPmsMemberList = async ({
  officeId,
  pageNumber,
  pageSize,
  IsForDoctor,
  SearchTerm,
}) => {
  const response = await axios.get(
    `${baseUrl}/Sikka/PatientDoctorList?OfficeId=${officeId}&PageNo=${pageNumber}&PageSize=${pageSize}&IsForDoctor=${IsForDoctor}&SearchTerm=${SearchTerm}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const useGetPmsMemberList = (
  officeId,
  pageNumber,
  pageSize,
  IsForDoctor,
  SearchTerm,
  options = {}
) => {
  return useQuery(
    ["pms-member-get-list", officeId, pageNumber, pageSize, IsForDoctor, SearchTerm],
    () => getPmsMemberList({ officeId, pageNumber, pageSize, IsForDoctor, SearchTerm }),
    options
  );
};

export const inviteSikkaPatient = async (payload) => {
  const response = await axios.post(
    `${patientSchedulingBaseUrl}/Doctor/InviteSikkaPatient`,
    payload
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const mannualInviteSikkaPatient = async (payload) => {
  const response = await axios.post(
    `${patientSchedulingBaseUrl}/Doctor/InviteSikkaPatientWithMiraxisEmail`,
    payload
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const getPraticeList = async (officeId) => {
  let res = await axios.get(`${baseUrl}/Sikka/PracticeList/${officeId}`);

  if (res.data.statusCode !== 200 && res.data.statusCode !== 400) {
    throw new Error(res.data.message);
  }

  return res.data;
};

export const manageGlobalNotification = async (params) => {
  let response = await axios.patch(
    `${baseUrl}/Sikka/GlobalNotificationStatusForPatient?SikkaDoctorPatientId=${params?.SikkaDoctorPatientId}&IsEnabledForGlobalNotifications=${params?.IsEnabledForGlobalNotifications}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data;
};

export const manageGlobalNotificationForOfficePreference = async (params) => {
  let response = await axios.patch(
    `${baseUrl}/Office/GlobalNotificationPreferenceForPatients?officeId=${params?.officeId}&IsGlobalNotificationEnabledForNonMiraxisPatients=${params?.IsGlobalNotificationEnabledForNonMiraxisPatients}&IsAutoInviteEnabledForPatients=${params?.IsAutoInviteEnabledForPatients}`
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data;
};