import axios from "axios";
import { Config } from "../config";
import { useMutation, useQuery } from "react-query";

const baseUrl = Config.serviceUrls.patientSchedulingBaseUrl;

const getMessageTemplatesList = async ({ pageNumber, pageSize, ownerId }) => {
  if (!ownerId) return;
  const response = await axios.get(
    `${baseUrl}/Template/TemplateList?pageNumber=${pageNumber}&pageSize=${pageSize}&ownerId=${ownerId}`
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response.data;
};

export const useGetMessageTemplatesList = (
  pageNumber,
  pageSize,
  ownerId,
  options = {}
) => {
  return useQuery(
    ["get-message-templates-list", pageNumber, pageSize, ownerId],
    () => getMessageTemplatesList({ pageNumber, pageSize, ownerId }),
    options
  );
};

export const deleteMessageTemplate = async ({ messageTemplateId }) => {
  const response = await axios.delete(`${baseUrl}/Template`, {
    params: {
      messageTemplateId,
    },
  });
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data.data;
};

export const useDeleteMessageTemplate = () => {
  return useMutation(deleteMessageTemplate);
};

export async function viewMessageTemplate(messageTemplateId) {
  const response = await axios.get(
    `${baseUrl}/Template?MessageTemplateId=${messageTemplateId}`
  );

  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data;
}

export async function getTemplateAccountOwners() {
  const response = await axios.get(`${baseUrl}/Template/Owner`);
 
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data?.data;
}

export function useTemplateAccountOwners(options = {}) {
  return useQuery(["/Template/Owner"], () => getTemplateAccountOwners(), options);
}

export async function getPlaceholder() {
  const response = await axios.get(`${baseUrl}/Template/PlaceHolder`);
 
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }

  return response?.data?.data;
}

export function useToGetPlaceholder(options = {}) {
  return useQuery(["/Template/PlaceHolder"], () => getPlaceholder(), options);
}

export const addMessageTemplate = async (params) => {
  const response = await axios.post(
    `${baseUrl}/Template`,
    params
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};

export const updateMessageTemplate = async (params) => {
  const response = await axios.put(
    `${baseUrl}/Template`,
    params
  );
  if (response.data.statusCode !== 200) {
    throw new Error(response.data.message);
  }
  return response.data;
};