import React, { useState } from "react";
import { connect } from "react-redux";
import { config, parameters, options } from "services/authProvider";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import constants from "./../../../constants";
import { MsalAuthProvider } from "react-aad-msal";
import { useAccountOwners } from "repositories/scheduler-repository";
import MessageDot from "components/MessageDot";
import userDefaultImage from "../../../assets/images/staff-default.svg";
import { Logout } from "repositories/user-repository";

const HeaderDropdown = (props) => {
  const { t, newNotification, isDisabledClass, redirectWithCheck, location } =
    props;
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownNotificationOpen, setDropdownNotificationOpen] =
    useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleNotificafation = () =>
    setDropdownNotificationOpen((prevState) => !prevState);
  const logout = async () => {
    let res = await Logout();
    if (res?.status) {
      localStorage.clear();
      sessionStorage.clear();
      new MsalAuthProvider(config, parameters, options).logout();
    }
  };

  const { role, profileSetupStep, userSubscription } = props.profile;
  const { data: ownerList } = useAccountOwners({
    enabled: role?.systemRole === constants.systemRoles.staff,
  });
  let dropdownItems = null;
  if (role.systemRole === constants.systemRoles.accountOwner) {
    dropdownItems = (
      <AccountOwnerDropdownItems
        profileSetupStep={profileSetupStep}
        subscription={userSubscription}
        t={t}
        isDisabledClass={isDisabledClass}
        redirectWithCheck={redirectWithCheck}
        location={location}
      />
    );
  } else if (role.systemRole === constants.systemRoles.superAdmin) {
    dropdownItems = <SuperAdminDropdownItems t={t} />;
  } else if (role.systemRole === constants.systemRoles.staff) {
    dropdownItems = (
      <StaffDropdownItems
        t={t}
        profile={props.profile}
        ownerList={ownerList}
        isDisabledClass={isDisabledClass}
        redirectWithCheck={redirectWithCheck}
        location={location}
      />
    );
  } else if (role.systemRole === constants.systemRoles.patient) {
    dropdownItems = <PatientDropdownItems t={t} />;
  }

  const moveToNotificationPage = () => {
    history.push(constants.routes.notification.notificationDetail);
  };

  let showNotificationIcon = true;
  if (role?.systemRole === constants.systemRoles.staff && !ownerList?.length) {
    showNotificationIcon = false;
  }

  const bellIconRoles = [
    constants.systemRoles.accountOwner,
    constants.systemRoles.staff,
  ];

  const addDefaultSrc = (ev) => {
    ev.target.src = userDefaultImage;
    ev.target.onerror = null;
  };

  return (
    <>
      {showNotificationIcon && (
        <Dropdown
          isOpen={dropdownNotificationOpen}
          toggle={toggleNotificafation}
          className="notify-dropdown"
        >
          <DropdownToggle caret={false} tag="div">
            {bellIconRoles.includes(role.systemRole) &&
              (newNotification ? (
                <img
                  src={require("assets/images/header-notification.svg").default}
                  alt="icon"
                  onClick={moveToNotificationPage}
                />
              ) : (
                <img
                  src={require("assets/images/Notification.svg").default}
                  alt="icon"
                  onClick={moveToNotificationPage}
                />
              ))}
          </DropdownToggle>
        </Dropdown>
      )}

      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret={false} className="user-dropdown" tag="div">
          <div className="usr-info">
            <div className="media">
              {props?.profile?.profilePic ? (
                <img
                  className="user-img"
                  src={props?.profile?.profilePic}
                  onError={addDefaultSrc}
                  alt="caret"
                />
              ) : (
                <img
                  className="user-img"
                  src={require("assets/images/staff-default.svg").default}
                  alt="usr"
                />
              )}

              <div className="responsive-dot-container">
                <MessageDot />
              </div>
              <div className="media-body align-self-center d-none d-md-block">
                <span>
                  {role.systemRole === constants.systemRoles.superAdmin
                    ? `${props.profile.firstName} ${props.profile.lastName}`
                    : props.profile.firstName}
                </span>
                <MessageDot />
                <img
                  src={require("assets/images/caret.svg").default}
                  alt="caret"
                />
              </div>
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu right>
          {dropdownItems}
          <DropdownItem onClick={logout}>
            <span className="menu-hover-effect">{t("navbar.logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

function AccountOwnerDropdownItems({
  profileSetupStep,
  subscription,
  t,
  isDisabledClass,
  redirectWithCheck,
  location,
}) {
  if (
    profileSetupStep === "packageExpired" ||
    profileSetupStep === "subscriptionTerminated" ||
    !subscription
  ) {
    return (
      <>
        <DropdownItem>
          <Link
            to={{
              pathname: constants.routes.addSubscription,
              state: { choosePlan: true },
            }}
          >
            <span className="menu-hover-effect">{t("navbar.chooseSubscription")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to={constants.routes.help}>
            <span className="menu-hover-effect">{t("navbar.help")}</span>
          </Link>
        </DropdownItem>
      </>
    );
  } else if (
    profileSetupStep === "completed" &&
    subscription &&
    subscription.packageType !== constants.packageTypes.free
  ) {
    return (
      <div className="superadmin-dropdown-list">
        <div className="dropdown-group">
          <DropdownItem>
            <Link to="/Offices">
              <span className="menu-hover-effect">{t("navbar.MyOffices")}</span>
            </Link>
          </DropdownItem>
        </div>

        <div className="dropdown-group">
          <DropdownItem
            className={isDisabledClass.scheduler}
            onClick={() => {
              redirectWithCheck(
                {
                  pathname: "/scheduler",
                  state: location?.state,
                },
                !!isDisabledClass.scheduler
              );
            }}
          >
            <span className="menu-hover-effect">{t("navbar.scheduler")}</span>
          </DropdownItem>
          <DropdownItem
            className={isDisabledClass.liveChat}
            onClick={() => {
              redirectWithCheck(
                {
                  pathname: constants.routes.chatModule,
                  state: location?.state,
                },
                !!isDisabledClass.liveChat
              );
            }}
          >
            <div className="d-flex pb-3">
              <span>{t("messenger.teamConversation")}</span>
              <MessageDot />
            </div>
          </DropdownItem>
        </div>

        <div className="dropdown-group">
          <DropdownItem>
            <Link to={constants.routes.accountOwner.profile}>
              <span className="menu-hover-effect">{t("navbar.viewMyProfile")}</span>
            </Link>
          </DropdownItem>

          <DropdownItem>
            <Link to={constants.routes.accountPreferences}>
              <span className="menu-hover-effect">{t("navbar.accountPreferences")}</span>
            </Link>
          </DropdownItem>

          <DropdownItem>
            <Link to="/change-password">
              <span className="menu-hover-effect">{t("navbar.changePassword")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/manage-plan">
              <span className="menu-hover-effect">{t("navbar.manageSubscription")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/staff-roles">
              <span className="menu-hover-effect">{t("navbar.addstaffRoles")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to="/manage-holidays">
              <span className="menu-hover-effect">{t("navbar.addholidays")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={constants.routes.accountOwner.officesList}>
              <span className="menu-hover-effect">{t("navbar.integrateOfficeWithPms")}</span>
            </Link>
          </DropdownItem>
        </div>

        <div className="dropdown-group">
          <DropdownItem>
            <Link to={constants.routes.help}>
              <span className="menu-hover-effect">{t("navbar.help")}</span>
            </Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={constants.routes.feedback}>
              <span className="menu-hover-effect">{t("navbar.feedback")}</span>
            </Link>
          </DropdownItem>
        </div>
      </div>
    );
  } else if (
    profileSetupStep === "completed" &&
    subscription &&
    subscription.packageType === constants.packageTypes.free
  ) {
    return (
      <>
        <DropdownItem>
          <Link to={constants.routes.accountOwner.profile}>
            <span className="menu-hover-effect">{t("navbar.viewProfile")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/manage-plan">
            <span className="menu-hover-effect">{t("navbar.manageSubscription")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/change-password">
            <span className="menu-hover-effect">{t("navbar.changePassword")}</span>
          </Link>
        </DropdownItem>
      </>
    );
  } else {
    return null;
  }
}

function SuperAdminDropdownItems({ t }) {
  return (
    <div className="superadmin-dropdown-list">
      <div className="dropdown-group">
        <DropdownItem>
          <Link to="/manage-owner">
            <span className="menu-hover-effect">{t("navbar.manageAccountOwners")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to={constants.routes.superAdmin.manageVendors}>
            <span className="menu-hover-effect">{t("navbar.manageVendors")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.superAdmin.manageSalesRepAdmin}>
            <span className="menu-hover-effect">{t("navbar.manageSalesRepresentatives")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/manage-personnel">
            <span className="menu-hover-effect">{t("navbar.managePersonnel")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to={constants.routes.superAdmin.patientList}>
            <span className="menu-hover-effect">{t("navbar.managePatients")}</span>
          </Link>
        </DropdownItem>
      </div>
      <div className="dropdown-group">
        <DropdownItem>
          <Link to={constants.routes.superAdmin.broadCastMessages}>
            <span className="menu-hover-effect">{t("superAdmin.broadcastMessages")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/demo-request">
            <span className="menu-hover-effect">{t("navbar.demoRequests")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.superAdmin.feedback}>
            <span className="menu-hover-effect">{t("navbar.feedback")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to={constants.routes.superAdmin.SupportAndHelp}>
            <span className="menu-hover-effect">{t("navbar.support")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.appVersionList}>
            <span className="menu-hover-effect">{t("navbar.appVersions")}</span>
          </Link>
        </DropdownItem>
      </div>
      <div className="dropdown-group">
        <DropdownItem>
          <Link to={constants.routes.superAdmin.reviews}>
            <span className="menu-hover-effect">{t("navbar.reviewsAndRatings")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.superAdmin.appointmentRequestList}>
            <span className="menu-hover-effect">{t("navbar.appointmentRequests")}</span>
          </Link>
        </DropdownItem>
      </div>
      <div className="dropdown-group">
        <DropdownItem>
          <Link to={constants.routes.superAdmin.vendorSubscriptionPlans}>
            <span className="menu-hover-effect">{t("navbar.vendorSubscriptionPlans")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/subscription-management">
            <span className="menu-hover-effect">{t("navbar.subscriptionPlans")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/staff-roles">
            <span className="menu-hover-effect">{t("navbar.addstaffRoles")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to={constants.routes.specialtyList}>
            <span className="menu-hover-effect">{t("navbar.specialtiesOrServices")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/manage-holidays">
            <span className="menu-hover-effect">{t("navbar.holidays")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/manage-testimonial">
            <span className="menu-hover-effect">{t("navbar.manageTestimonials")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/manage-content">
            <span className="menu-hover-effect">{t("navbar.manageContent")}</span>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link to={constants.routes.superAdmin.TopUpPromotions}>
            <span className="menu-hover-effect">{t("navbar.manageTopUp")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.superAdmin.categories}>
            <span className="menu-hover-effect">{t("navbar.manageCategories")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.superAdmin.tax}>
            <span className="menu-hover-effect">{t("navbar.taxManagement")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to={constants.routes.superAdmin.manageCommissions}>
            <span className="menu-hover-effect">{t("navbar.manageCommissions")}</span>
          </Link>
        </DropdownItem>
      </div>
      <div className="dropdown-group">
        <DropdownItem>
          <Link to="/edit-profile">
            <span className="menu-hover-effect">{t("navbar.viewMyProfile")}</span>
          </Link>
        </DropdownItem>

        <DropdownItem>
          <Link to="/change-password">
            <span className="menu-hover-effect">{t("navbar.changePassword")}</span>
          </Link>
        </DropdownItem>
      </div>
    </div>
  );
}

function StaffDropdownItems({
  t,
  profile,
  ownerList,
  isDisabledClass,
  redirectWithCheck,
  location,
}) {
  const { isAdmin, isMessenger } = profile;
  return (
    <>
      {!!(ownerList && ownerList.length) && (
        <DropdownItem
          className={isDisabledClass.scheduler}
          onClick={() => {
            redirectWithCheck(
              {
                pathname: "/scheduler",
                state: location?.state,
              },
              !!isDisabledClass.scheduler
            );
          }}
        >
          <span className="menu-hover-effect">{t("navbar.scheduler")}</span>
        </DropdownItem>
      )}
      {(isAdmin || !!isMessenger) && (
        <DropdownItem
          className={isDisabledClass.liveChat}
          onClick={() => {
            redirectWithCheck(
              {
                pathname: constants.routes.chatModule,
                state: location?.state,
              },
              !!isDisabledClass.liveChat
            );
          }}
        >
          <>
            <div className="d-flex pt-3 pb-3">
              <span>{t("messenger.teamConversation")}</span>
              <MessageDot />
            </div>
          </>
        </DropdownItem>
      )}
      <DropdownItem>
        <Link to={constants.routes.viewProfile}>
          <span className="menu-hover-effect">{t("navbar.viewProfile")}</span>
        </Link>
      </DropdownItem>
      {!profile?.isStaffCreatedBySignUp && <DropdownItem>
        <Link to={constants.routes.accountPreferences}>
          <span className="menu-hover-effect">{t("navbar.accountPreferences")}</span>
        </Link>
      </DropdownItem>}
      <DropdownItem>
        <Link to={constants.routes.help}>
          <span className="menu-hover-effect">{t("navbar.help")}</span>
        </Link>
      </DropdownItem>
      <DropdownItem>
        <Link to={constants.routes.feedback}>
          <span className="menu-hover-effect">{t("navbar.feedback")}</span>
        </Link>
      </DropdownItem>
    </>
  );
}

function PatientDropdownItems({ t }) {
  return (
    <>
      <DropdownItem>
        <Link to={constants.routes.doctors}>
          <span className="menu-hover-effect">{t("doctors")}</span>
        </Link>
      </DropdownItem>
      <DropdownItem>
        <Link to={constants.routes.familyMembers}>
          <span className="menu-hover-effect">{t("navbar.familyMembers")}</span>
        </Link>
      </DropdownItem>
      <DropdownItem>
        <Link to={constants.routes.help}>
          <span className="menu-hover-effect">{t("navbar.help")}</span>
        </Link>
      </DropdownItem>
      <DropdownItem>
        <Link to={constants.routes.feedback}>
          <span className="menu-hover-effect">{t("navbar.feedback")}</span>
        </Link>
      </DropdownItem>
    </>
  );
}

const mapStateToProps = ({ userProfile: { profile } }) => ({
  profile,
});
export default connect(
  mapStateToProps,
  null
)(withTranslation()(HeaderDropdown));
