import React from "react";
import BoothIcon from "../../../assets/images/past-booth-icon.png";
import CalendarIcon from "../../../assets/images/past-event-calendar-icon.png";
import LocationIcon from "../../../assets/images/event-location-icon.png";
import styles from "../style.module.scss";
import Text from "components/Text";
import EventsCommonCard from "./EventsCommonCard";

export default function PastCard({ icon, link, image, date, booth, eventHeading }) {
  return (
    <div className={styles["past-event-page"] + " " + "container"}>
      <article className={styles["event-card"]}>
        <EventsCommonCard eventHeading={eventHeading} icon={icon} link={link} image={image}  />
        <aside className={styles["event-aside"]}>
          <div className={styles["past-event-etail"]}>
            <div className={styles["past-icon-box"]}>
              <img src={CalendarIcon} alt="event-icon" />
            </div>
            <Text
              size="18px"
              weight="400"
              color="#2D3245"
              className={styles["past-event-date"]}
            >
              {date}
            </Text>
          </div>
          <div className={styles["past-event-etail"]}>
            <div className={styles["past-icon-box"]}>
              <img src={LocationIcon} alt="event-icon" />
            </div>
            <Text
              size="18px"
              weight="400"
              color="#2D3245"
              className={styles["past-event-date"]}
            >
              Vancouver, Canada
            </Text>
          </div>
          <div className={styles["past-event-etail"]}>
            <div className={styles["past-icon-box"]}>
              <img src={BoothIcon} alt="event-icon" />
            </div>
            <Text
              size="18px"
              weight="400"
              color="#2D3245"
              className={styles["past-event-date"]}
            >
              {booth}
            </Text>
          </div>
        </aside>
      </article>
    </div>
  );
}
