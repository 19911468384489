import React, { Fragment, useEffect, useState } from "react";
import Page from "components/Page";
import styles from "../../accountpreferences.module.scss";
import "../../AccountPreference.scss";
import Text from "components/Text";
import { Card } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import {
  encodeId,
  getStorage,
  handleError,
  handleSuccess,
  setStorage,
} from "utils";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {
  useDeleteMessageTemplate,
  useGetMessageTemplatesList,
  useTemplateAccountOwners,
} from "repositories/message-templates-repository";
import useHandleApiError from "hooks/useHandleApiError";
import useRemoveCache from "hooks/useRemoveCache";
import constants from "./../../../../constants";
import Empty from "components/Empty";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import CustomModal from "components/CustomModal";
import { sortBy } from "lodash";
import SwitchAccountOwnerModal from "pages/AccountPreferences/modals/SwitchAccountOwnerModal/SwitchAccountOwnerModal";

const PAGE_SIZE = 4;

const MessageTemplates = ({ history, t }) => {
  const profile = useSelector((state) => state?.userProfile?.profile);
  const isAccountOwner =
    profile?.role?.systemRole === constants.systemRoles.accountOwner;

  const cacheValue = getStorage(constants.messageTemplates.cache.list);
  const [pageNumber, setPageNumber] = useState(cacheValue?.pageNumber || 1);
  const [list, setList] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [showSwitchOwnerModal, setShowSwitchOwnerModal] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  const { data: ownerData } = useTemplateAccountOwners({
    enabled: !isAccountOwner,
  });

  const {
    data,
    error: isError,
    isLoading,
    isFetching,
    refetch,
  } = useGetMessageTemplatesList(
    pageNumber,
    PAGE_SIZE,
    isAccountOwner ? profile?.id : selectedOwner?.id
  );

  const deleteTemplateMutation = useDeleteMessageTemplate();
  const { isLoading: deletingTemplate } = deleteTemplateMutation;

  let totalItems = data?.pagination?.totalItems;
  useHandleApiError(isLoading, isFetching, isError);

  useEffect(() => {
    setStorage(constants.messageTemplates.cache.list, {
      pageNumber: pageNumber,
    });
  }, [pageNumber]);

  useRemoveCache([], constants.messageTemplates.cache.list);

  useEffect(() => {
    if (data?.data) {
      setList(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (!isAccountOwner && ownerData?.length > 0) {
      let _selectedOwner = JSON.parse(localStorage.getItem("selectedOwner"));

      if (_selectedOwner) {
        setSelectedOwner(_selectedOwner);
        localStorage.setItem("selectedOwner", JSON.stringify(_selectedOwner));
      } else {
        setSelectedOwner(ownerData[0]);
        localStorage.setItem("selectedOwner", JSON.stringify(ownerData[0]));
      }
    }
  }, [ownerData, isAccountOwner]);

  const goBack = () => {
    history.push("/account-preferences");
  };

  const handleDropDown = (itemId) => {
    const updatedList = list.map((item) => {
      if (item.id === itemId) {
        return { ...item, isDropDownOpen: !item.isDropDownOpen };
      }

      return item;
    });

    setList(updatedList);
  };

  const renderHeader = () => (
    <div
      className={
        styles["message-templates-heading"] +
        " " +
        "d-flex justify-content-between"
      }
    >
      <p className={styles["select-office"]}>
        {t("accountPreferences.messageTemplates")}
      </p>
      <Link to={constants.routes.accountPreference.addMessageTemplates}>
        <button className="button button-round button-shadow mr-md-4 mb-3 w-sm-100">
          {t("accountPreferences.addNewMessageTemplate")}
        </button>
      </Link>
    </div>
  );

  const renderQuickActions = (item) => (
    <Dropdown
      className="template-action"
      isOpen={item.isDropDownOpen}
      toggle={(e) => {
        e.stopPropagation();
        handleDropDown(item.id);
      }}
    >
      <DropdownToggle caret={false} tag="div">
        <span className="ico">
          <img
            src={require("assets/images/dots-icon.svg").default}
            alt="icon"
          />
        </span>
      </DropdownToggle>
      <DropdownMenu right className={styles["office-dropdown-menu"]}>
        <Fragment>
          <DropdownItem
            className={styles["office-dropdown-item"] + " " + "menu-hover-effect" }
            onClick={() => {
              editTemplate(item);
            }}
          >
            <span>{t("edit")}</span>
          </DropdownItem>
          <DropdownItem
            className={styles["office-dropdown-item"] + " " + "menu-hover-effect"}
            onClick={() => handleDelete(item)}
          >
            <span>{t("delete")}</span>
          </DropdownItem>
        </Fragment>
      </DropdownMenu>
    </Dropdown>
  );

  const viewTemplate = (e, item) => {
    e.stopPropagation();
    history.push(
      constants.routes.accountPreference.viewMessageTemplates.replace(
        ":templateId",
        encodeId(item?.id)
      )
    );
  };

  const handleDelete = (template) => {
    setConfirmModal(true);
    setSelectedTemplate(template);
  };

  const confirmDeleteTemplate = async (messageTemplateId) => {
    try {
      await deleteTemplateMutation.mutateAsync({
        messageTemplateId,
      });

      handleSuccess(t("accountPreferences.deleteMsg"));
      refetch();

      setConfirmModal(false);
    } catch (err) {
      handleError(err);
    }
  };

  const editTemplate = (item) => {
    history.push(
      constants.routes.accountPreference.editMessageTemplates.replace(
        ":templateId",
        encodeId(item?.id)
      )
    );
  };

  const sortedOwnerList = (ownerList) => {
    return sortBy(ownerList, [(owner) => owner.firstName?.toLowerCase()]);
  };

  const switchNewAccountOwner = (owner) => {
    if (owner) {
      setSelectedOwner(owner);
      localStorage.setItem("selectedOwner", JSON.stringify(owner));
    }
  };

  const renderList = () => (
    <>
      {!!list?.length ? (
        list.map((item) => (
          <Card
            onClick={(e) => {
              setSelectedTemplate(item);
              viewTemplate(e, item);
            }}
            className={
              styles["message-templates-card"] +
              " " +
              "form-wrapper cursor-pointer"
            }
            key={item.id}
          >
            <div className={styles["schedule-update"]}>
              <Text
                color="#587E85"
                weight="600"
                size="16px"
                marginBottom="20px"
                className={styles["schedule-update-text"]}
              >
                {item?.templateName}
              </Text>
              {item?.creator?.id === profile?.id &&
                renderQuickActions(item)}
            </div>
            <div>
              <div>
                <Text color="#6F7788" weight="400" size="12px">
                  {t("messenger.createdBy")}
                </Text>
                <Text color="#102C42" weight="600" size="14px">
                  {item?.creator?.id === profile?.id
                    ? t("accountPreferences.you")
                    : `${item?.creator?.firstName} ${item?.creator?.lastName}`}
                </Text>
              </div>
            </div>
          </Card>
        ))
      ) : (
        <Empty Message={t("noRecordFound")} />
      )}
    </>
  );

  const renderOwnerSwitcher = () => (
    <>
      {!isAccountOwner && (
        <div className="top-right-text">
          {selectedOwner && (
            <div className="show-text">
              {t("accountPreferences.showingTemplates")}{" "}
              <b>
                '{selectedOwner.firstName} {selectedOwner.lastName}'
              </b>
            </div>
          )}
          {selectedOwner && (
            <span
              className="link-btn"
              onClick={() => {
                setShowSwitchOwnerModal(true);
              }}
            >
              {t("change")}
            </span>
          )}
        </div>
      )}
    </>
  );

  const renderPagination = () => (
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        sizePerPage: PAGE_SIZE,
        totalSize: totalItems,
        page: pageNumber,
        onPageChange: setPageNumber,
      })}
    >
      {({ paginationProps, paginationTableProps }) => {
        return (
          <div className="data-table-block">
            {/* Paginator component needs table to work, this is why we have used it.  */}
            <div style={{ display: "none" }}>
              <BootstrapTable
                keyField="id"
                data={[]}
                columns={[{ text: "sometext" }]}
                {...paginationTableProps}
              />
            </div>
            <div className={"pagnation-block "}>
              {totalItems > PAGE_SIZE && (
                <PaginationListStandalone {...paginationProps} />
              )}
            </div>
          </div>
        );
      }}
    </PaginationProvider>
  );

  return (
    <>
      <Page onBack={goBack} className={styles["global-main-page"]}>
        {isLoading && <Loader />}
        <div className={styles["message-templates"]}>
          {renderHeader()}
          {ownerData?.length > 0 && renderOwnerSwitcher()}
          {renderList()}
        </div>
        {renderPagination()}
      </Page>
      {confirmModal && (
        <CustomModal
          isOpen={confirmModal}
          setIsOpen={setConfirmModal}
          actionInProgress={deletingTemplate}
          leftBtnText={t("delete")}
          rightBtnText={t("cancel")}
          title={t("accountPreferences.deleteModalTitle")}
          subTitle1={t("accountPreferences.deleteModalContent")}
          onConfirm={() => confirmDeleteTemplate(selectedTemplate.id)}
        />
      )}
      {showSwitchOwnerModal && (
        <SwitchAccountOwnerModal
          subTitle={t("accountPreferences.template")}
          showSwitchOwnerModal={showSwitchOwnerModal}
          setShowSwitchOwnerModal={setShowSwitchOwnerModal}
          ownerList={sortedOwnerList(ownerData || [])}
          selectedOwner={selectedOwner}
          setSelectedOwner={switchNewAccountOwner}
        />
      )}
    </>
  );
};

export default withTranslation()(MessageTemplates);
