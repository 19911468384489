import React, { useState } from "react";
import Input from "components/Input";
import styles from "./EditTabs.module.scss";
import cameraIconWithBg from "../../../assets/images/camera-icon-with-bg.svg";
import Text from "components/Text";
import BrandEmailTemplate from "./BrandEmailTemplate";
import { withTranslation } from "react-i18next";
import { withGoogleAutoSuggestion } from ".";

const Branding = ({
  t,
  brandingValues,
  errors,
  InputChange,
  handleLogoChange,
  officeLogo,
  handleCheckboxChange,
  handlePlaceSelect,
  googleServices,
  selectedCountry,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showMapPredictions, setShowMapPredictions] = useState();
  const [previewType, setPreviewType] = useState("");

  const {
    emailBrandingOfficeName,
    emailBrandingOfficeAddress,
    emailBrandingOfficeEmail,
    emailBrandingOfficeContactNumber,
    officeWebSiteLink,
    officeInstagramLink,
    officeLinkedinLink,
    officeFacebookLink,
    isLocationEnabledForPatientAppointmentEmailReminder,
    isCalendarEventEnabledForPatientAppointmentEmailReminder,
    isLocationEnabledForPatientAppointmentConfirmationEmail,
    isCalendarEventEnabledForPatientAppointmentConfirmationEmail,
    isLocationEnableForPatientEmailRecallRemider,
    isLocationEnableForSystemEmailToPatientOwnerAndStaff,
  } = brandingValues;

  const placePredictionContent = () => {
    return googleServices.placePredictions.map((place) => {
      const { description, place_id } = place;

      return (
        <li
          key={place_id}
          onClick={() => {
            setTimeout(() => {
              setShowMapPredictions(false);
              handlePlaceSelect(place_id, description);
            }, 300);
          }}
        >
          {description}as
        </li>
      );
    });
  };

  return (
    <div className={styles["branding-container"]}>
      <div className={styles["branding_left_container"] + " " + "col-lg-8"}>
        <div className={styles["input_wrapper"]}>
          <Input
            Title={t("form.fields.officeName")}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("form.fields.officeName"),
            })}
            Name={"emailBrandingOfficeName"}
            HandleChange={InputChange}
            Error={errors.emailBrandingOfficeName}
            Value={emailBrandingOfficeName}
          />
          <Input
            Title={t("officeBranding.officeEmail")}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("officeBranding.officeEmail"),
            })}
            Name={"emailBrandingOfficeEmail"}
            HandleChange={InputChange}
            Error={errors.emailBrandingOfficeEmail}
            Value={emailBrandingOfficeEmail}
          />
          <Input
            Title={t("officeBranding.officePhoneNumber")}
            MaxLength={15}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("officeBranding.officePhoneNumber"),
            })}
            Name={"emailBrandingOfficeContactNumber"}
            HandleChange={InputChange}
            Error={errors.emailBrandingOfficeContactNumber}
            Value={emailBrandingOfficeContactNumber}
          />
          <div className={styles["location-input-wrapper"]}>
            <Input
              Title={t("form.fields.officeAddress")}
              Type="text"
              Placeholder={t("form.placeholder1", {
                field: t("form.fields.officeAddress"),
              })}
              Name={"emailBrandingOfficeAddress"}
              HandleChange={(e) => {
                InputChange(e);
                if (!showMapPredictions) {
                  setShowMapPredictions(true);
                }
                googleServices.getPlacePredictions({
                  input: e.target.value,
                  componentRestrictions: {
                    country: selectedCountry,
                  },
                });
              }}
              Error={errors.emailBrandingOfficeAddress}
              Value={emailBrandingOfficeAddress}
            />
            <ul
              className={
                styles["location-list"] +
                `${!showMapPredictions ? "d-none" : ""}`
              }
            >
              {showMapPredictions && placePredictionContent()}
            </ul>
          </div>
          <Input
            Title={t("officeBranding.websiteLink")}
            MaxLength={1000}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("officeBranding.websiteLink"),
            })}
            Name={"officeWebSiteLink"}
            HandleChange={InputChange}
            Value={officeWebSiteLink}
          />
          <Input
            Title={t("officeBranding.instagramLink")}
            MaxLength={500}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("officeBranding.instagramLink"),
            })}
            Name={"officeInstagramLink"}
            HandleChange={InputChange}
            Value={officeInstagramLink}
          />
          <Input
            Title={t("officeBranding.facebookLink")}
            MaxLength={1000}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("officeBranding.facebookLink"),
            })}
            Name={"officeFacebookLink"}
            HandleChange={InputChange}
            Value={officeFacebookLink}
          />
          <Input
            Title={t("officeBranding.linkedinLink")}
            MaxLength={1000}
            Type="text"
            Placeholder={t("form.placeholder1", {
              field: t("officeBranding.linkedinLink"),
            })}
            Name={"officeLinkedinLink"}
            HandleChange={InputChange}
            Value={officeLinkedinLink}
          />

          <div>
            <Text size="16px" marginBottom="10px" weight="600" color="#587E85">
              {t("officeBranding.brandingMoreElements")}
            </Text>
            <Text size="14px" marginBottom="20px" weight="400" color="#102C42">
              {t("officeBranding.systemGeneratedEmails")}
            </Text>
            <div>
              <div className={styles["check_box_container"]}>
                <p className={styles["check_box_text"]}>
                  {t("officeBranding.systemEmailsToPateint")}
                </p>
                <p
                  className={styles["checkbox_preview_text"]}
                  onClick={() => {
                    setPreviewType("systemEmailsToPateint")
                    setModalOpen(true)
                  }}
                >
                  {t("officeBranding.preview")}
                </p>
              </div>
              <div className={styles["checkbox_wrapper"]}>
                <div className="ch-checkbox c-field all-event-checkbox">
                  <label>
                    <input
                      onChange={handleCheckboxChange}
                      name="isLocationEnableForSystemEmailToPatientOwnerAndStaff"
                      type="checkbox"
                      checked={
                        isLocationEnableForSystemEmailToPatientOwnerAndStaff
                      }
                    />
                    <span>{t("officeBranding.locationOnMap")}</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className={styles["check_box_container"]}>
                <p className={styles["check_box_text"]}>
                  {t("officeBranding.patientEmailReminder")}
                </p>
                <p
                  className={styles["checkbox_preview_text"]}
                  onClick={() => {
                    setPreviewType("patientAppointmentReminder")
                    setModalOpen(true)
                  }}
                >
                  {t("officeBranding.preview")}
                </p>
              </div>
              <div className={styles["checkbox_wrapper"]}>
                <div className="ch-checkbox c-field all-event-checkbox">
                  <label>
                    <input
                      onChange={handleCheckboxChange}
                      name="isLocationEnabledForPatientAppointmentEmailReminder"
                      type="checkbox"
                      checked={
                        isLocationEnabledForPatientAppointmentEmailReminder
                      }
                    />
                    <span>{t("officeBranding.locationOnMap")}</span>
                  </label>
                </div>
                <div className="ch-checkbox c-field all-event-checkbox">
                  <label>
                    <input
                      onChange={handleCheckboxChange}
                      name="isCalendarEventEnabledForPatientAppointmentEmailReminder"
                      type="checkbox"
                      checked={
                        isCalendarEventEnabledForPatientAppointmentEmailReminder
                      }
                    />
                    <span>{t("officeBranding.addToCalendar")}</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className={styles["check_box_container"]}>
                <p className={styles["check_box_text"]}>
                  {t("officeBranding.patientRecallReminder")}
                </p>
                <p
                  className={styles["checkbox_preview_text"]}
                  onClick={() => {
                    setPreviewType("patientRecallReminder")
                    setModalOpen(true)
                  }}
                >
                  {t("officeBranding.preview")}
                </p>
              </div>
              <div className={styles["checkbox_wrapper"]}>
                <div className="ch-checkbox c-field all-event-checkbox">
                  <label>
                    <input
                      onChange={handleCheckboxChange}
                      name="isLocationEnableForPatientEmailRecallRemider"
                      type="checkbox"
                      checked={isLocationEnableForPatientEmailRecallRemider}
                    />
                    <span>{t("officeBranding.locationOnMap")}</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className={styles["check_box_container"]}>
                <p className={styles["check_box_text"]}>
                  {t("officeBranding.PatientAppointmentConfirmation")}
                </p>
                <p
                  className={styles["checkbox_preview_text"]}
                  onClick={() => {
                    setPreviewType("patientConfirmationEmail")
                    setModalOpen(true)
                  }}
                >
                  {t("officeBranding.preview")}
                </p>
              </div>
              <div className={styles["checkbox_wrapper"]}>
                <div className="ch-checkbox c-field all-event-checkbox">
                  <label>
                    <input
                      onChange={handleCheckboxChange}
                      name="isLocationEnabledForPatientAppointmentConfirmationEmail"
                      type="checkbox"
                      checked={
                        isLocationEnabledForPatientAppointmentConfirmationEmail
                      }
                    />
                    <span>{t("officeBranding.locationOnMap")}</span>
                  </label>
                </div>
                <div className="ch-checkbox c-field all-event-checkbox">
                  <label>
                    <input
                      onChange={handleCheckboxChange}
                      name="isCalendarEventEnabledForPatientAppointmentConfirmationEmail"
                      type="checkbox"
                      checked={
                        isCalendarEventEnabledForPatientAppointmentConfirmationEmail
                      }
                    />
                    <span>{t("officeBranding.addToCalendar")}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["file-upload-container"] + " " + " col-lg-4"}>
        <div className={styles["file-upload-field"]}>
          <div className={styles["office-image"]}>
            {officeLogo ? (
              <img src={officeLogo} alt="upload" />
            ) : (
              <img src={cameraIconWithBg} alt="upload" />
            )}
          </div>
          <div
            className={styles["ch-upload-button"] + " " + "ch-upload-button"}
          >
            <input
              id="fileUpload-officeLogo"
              type="file"
              onChange={handleLogoChange}
            />
            <span>
              <img
                width="32px"
                height="32px"
                src={require("assets/images/upload-image.svg").default}
                alt="upload"
              />
            </span>
          </div>
        </div>
        <div className={styles["upload-help-text"] + " " + "mt-4"}>
          {t("accountOwner.uploadOfficeLogoDescription")}
        </div>
        {Object.keys(errors).length > 0 && (
          <span className="error-msg text-center">{errors.officeLogo} </span>
        )}
      </div>
      {isModalOpen && (
        <BrandEmailTemplate
          isModalOpen={isModalOpen}
          closeModal={() => setModalOpen(false)}
          modalContent={brandingValues}
          officeLogo={officeLogo}
          previewType={previewType}
        />
      )}
    </div>
  );
};
export default withTranslation()(withGoogleAutoSuggestion(Branding));
