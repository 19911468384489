import React from "react";

import CalendarIcon from "../../../assets/images/calendar-icon.jpg";
import styles from "../style.module.scss";
import Text from "components/Text";
import EventsCommonCard from "./EventsCommonCard";
import ArrowOutward from "../../../assets/images/landing-pages/arrow_outward.png";

export default function EventsCard({
  icon,
  link,
  image,
  date,
  booth,
  subHeading,
  eventHeading,
}) {
  return (
    <div className={styles["upcoming-event-page"] + " " + "container"}>
      <article className={styles["event-card"]}>
        <EventsCommonCard
          icon={icon}
          eventHeading={eventHeading}
          link={link}
          image={image}
          subHeading={subHeading}
        />
        <aside className={styles["event-aside"]}>
          <Text className={styles["event-date"]}>{date}</Text>
          <div className={styles["aside-content-box"]}>
            <div className={styles["booth-detail"]}>
              <div className={styles["calendar-icon-box"]}>
                <img src={CalendarIcon} alt="event-icon" />
              </div>
              <span className={styles["booth-id"]}>{booth}</span>
            </div>
            <a
              href="https://meetings.hubspot.com/madjid-rassamanesh/jdiq24"
              target="blank"
              className={styles["custom-events-button"]}
            >
              <button className="button button-round button-shadow w-sm-100">
                Book an Onsite Demo
                <img
                  src={ArrowOutward}
                  className={styles["arrow-outward"]}
                  alt="event-icon"
                />
              </button>
            </a>
          </div>
        </aside>
      </article>
    </div>
  );
}
