import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import Text from "components/Text";
import "./InfoModal.scss";

const InfoModal = ({
  isOpen,
  setIsOpen,
  title,
  content,
  subContent,
  contactEmail,
  contactNumber,
  btnText = "",
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        className="modal-dialog-centered  delete-modal"
        modalClassName="custom-modal"
        toggle={setIsOpen}
      >
        <span className="close-btn" onClick={setIsOpen}>
          <img src={require("assets/images/cross.svg").default} alt="close" />
        </span>
        <ModalBody>
          <div className="change-modal-content text-center">
            <Text size="25px" marginBottom="10px" weight="500" color="#111b45">
              <span className="modal-title-25"> {title}</span>{" "}
            </Text>
            {content && (
              <Text
                size="16px"
                marginBottom="20px"
                weight="300"
                color=" #535b5f"
              >
                {content}
              </Text>
            )}
            {subContent && (
              <Text
                size="16px"
                marginBottom="5px"
                weight="300"
                color=" #535b5f"
              >
                {subContent}
              </Text>
            )}
            {contactEmail && (
              <Text size="16px" weight="300" color=" #535b5f">
                {contactEmail}
              </Text>
            )}
            {contactNumber && (
              <Text size="16px" weight="300" color=" #535b5f" marginBottom="20px">
                {contactNumber}
              </Text>
            )}

            <div className="btn-box">
              <button
                className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
                title={btnText}
                onClick={setIsOpen}
              >
                {btnText}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InfoModal;
