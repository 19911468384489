import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import { Modal } from "reactstrap";
import ModalBody from "reactstrap/lib/ModalBody";
import Text from "components/Text";
import styles from "./Modals.module.scss";
import crossIcon from "./../../../../assets/images/cross.svg";
import { useToGetPlaceholder } from "repositories/message-templates-repository";
import Loader from "components/Loader";

function PlaceholdersModal({
  t,
  showPlaceholderModal,
  setShowPlaceholderModal,
  handleSelectedPlaceholder
}) {
  const [placeholderList, setPlaceholderList] = useState([]);
  const [selectedVal, setSelectedVal] = useState({});
  const { data, isLoading } = useToGetPlaceholder();
  
  useEffect(() => {
    if (data?.length > 0) {
      setPlaceholderList(data);
    }
  }, [data]);

  return (
    <>
      <Modal
        isOpen={showPlaceholderModal}
        toggle={() => setShowPlaceholderModal(false)}
        className={
          "modal-dialog-centered modal-width-660 " +
          styles["change-schedule-modal-dialog"]
        }
        modalClassName="custom-modal"
      >
        <span
          className="close-btn"
          onClick={() => setShowPlaceholderModal(false)}
        >
          <img src={crossIcon} alt="close" />
        </span>
        <ModalBody>
            {isLoading && <Loader />}
          <Text size="25px" marginBottom="10px" weight="500" color="#111b45">
            <span className="modal-title-25">
              {t("accountPreferences.insert")}
            </span>
          </Text>

          <ul className={styles["change-list"]}>
            {placeholderList &&
              placeholderList.length > 0 &&
              placeholderList.map((item, key) => (
                <li key={item?.id}>
                  <div className="ch-radio">
                    <label className="mr-5">
                      <input
                        type="radio"
                        readOnly
                        onClick={() => setSelectedVal(item)}
                        checked={item?.id === selectedVal?.id}
                        name="placeholder"
                      />
                      <span>{item.placeHolder}</span>
                    </label>
                  </div>
                </li>
              ))}
          </ul>

          <button
            className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
            title={t("accountPreferences.insert")}
            onClick={() => {
                handleSelectedPlaceholder(selectedVal?.placeHolder)
                setShowPlaceholderModal(false);
            }}
          >
            {t("accountPreferences.insert")}
          </button>
          <button
            className="button button-round button-border button-dark btn-mobile-link "
            onClick={() => {
              setShowPlaceholderModal(false);
            }}
            title={t("cancel")}
          >
            {t("cancel")}
          </button>
        </ModalBody>
      </Modal>
    </>
  );
}

export default withTranslation()(PlaceholdersModal);
