import React from "react";
import styles from "../style.module.scss";
import Text from "components/Text";
export default function EventsCommonCard({ icon, link, image, subHeading, eventHeading }) {
  return (
    <section className={styles["event-details"]}>
      <div className={styles["event-icon"]}>
        <img src={icon} alt="event-icon" />
      </div>
      <div className={styles["event-wrapper"]}>
        <div className={styles["event-content"]}>
          <a href={link} target="blank" className={styles["events-link"]}>
            {eventHeading}
          </a>
        </div>
        {subHeading && <Text className={styles["asm-subheading"]}>{subHeading}</Text>}
        <div className={styles["event-image"]}>
          <img src={image} alt="event-image" />
        </div>
      </div>
    </section>
  );
}
