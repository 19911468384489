import Page from "components/Page";
import { withTranslation } from "react-i18next";
import React, { useState, Suspense, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Card from "components/Card";
import Loader from "components/Loader";
import useHandleApiError from "hooks/useHandleApiError";
import { useParams } from "react-router-dom";
import {
  getStorage,
  setStorage,
  decodeId,
  encodeId,
  removeStorage,
} from "utils";
import constants from "../../../constants";
import { useOfficeDetail } from "repositories/office-repository";
import Doctors from "./components/Doctors";
import Patients from "./components/Patients";
import useToGetPmsPatientsList from "./hooks/useToGetPmsPatientsList";
import "./pms_new.scss";

const PMSMembers = ({ t, history, location }) => {
  const officeId = decodeId(useParams()?.officeId);

  /* for go back to previous page */
  const onBack = () => history.push(`/Staff/${encodeId(officeId)}`);

  /* Initializations & Declarations */
  const cachedActiveTab = getStorage(
    constants.pmsMembers.cache.pmsMemberlisting
  );

  const [activeTab, setActiveTab] = useState(
    cachedActiveTab?.activeTab ? cachedActiveTab.activeTab : 1
  );

  useEffect(() => {
    return () => {
      removeStorage([
        constants.pmsMembers.cache.pmsMemberlisting,
        constants.pmsMembers.cache.pmsPatientslisting,
        constants.pmsMembers.cache.pmsDoctorslisting,
      ]);
    };
  }, []);

  const tabName = constants.pmsMemberTabStatus;

  const {
    data: officeDetail,
    error: officeDetailError,
    isLoading: loadingOfficeDetails,
    isFetching: fetchingOfficeDetails,
  } = useOfficeDetail(officeId);

  useHandleApiError(
    loadingOfficeDetails,
    fetchingOfficeDetails,
    officeDetailError
  );

  /**
   * @method: [manageTab]
   * @description: this method will call when we change the tab
   * @param {string} tab
   */
  const manageTab = (tab) => {
    window.scrollTo(0, 0);
    setActiveTab(tab);
    setStorage(constants.pmsMembers.cache.pmsMemberlisting, {
      activeTab: tab,
    });
  };

  const patientHookData = useToGetPmsPatientsList({
    t,
    tabStatus: tabName.patients,
    officeId,
    IsForDoctor: false,
    pageSize: 10,
  });

  const { patientData, methods } = patientHookData;

  const { selectedIds, pmsPatientsList } = patientData;
  const { sendInvite } = methods;

  const canDiableSendInviteButton = () => {
    const sikkaEmailIds = [];
    const pateintsContactNumber = [];
    pmsPatientsList?.map((val) => {
      if (selectedIds.includes(val?.id)) {
        if (val?.sikkaEmailId) sikkaEmailIds.push(val.sikkaEmailId);
        if (val?.contactNumber) pateintsContactNumber.push(val.contactNumber);
      }
    });

    return sikkaEmailIds.length <= 0 && pateintsContactNumber.length <= 0;
  };

  const renderHeader = () => (
    <>
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="">
          {officeDetail && officeDetail.name && (
            <h2 className="title pms_heading">{officeDetail.name}</h2>
          )}
          <p className="pms_subtitle">{t("pmsMembers.pmsDoctorsAndPatient")}</p>
        </div>
        <div>
          {activeTab === tabName.patients && (
            <button
              className={`button button-round button-width-large add-button ${
                selectedIds && canDiableSendInviteButton()
                  ? "header_disabled_btn"
                  : ""
              }`}
              onClick={() => sendInvite(null)}
              title={t("staffTimesheet.exportTimesheetData")}
            >
              {t("pmsMembers.sendInvite")}
            </button>
          )}
        </div>
      </div>
    </>
  );

  return (
    <Page className={""} onBack={onBack}>
      {renderHeader()}
      <Card>
        <div className={"common-tabs scheduler-tabs "}>
          <Nav tabs className={""}>
            <NavItem>
              <NavLink
                className={activeTab == tabName.doctors ? "active" : ""}
                onClick={() => manageTab(tabName.doctors)}
              >
                {t("pmsMembers.doctors")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab == tabName.patients ? "active" : ""}
                onClick={() => manageTab(tabName.patients)}
              >
                {t("pmsMembers.patients")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <Suspense fallback={<Loader />}>
              <TabPane tabId={1}>
                {activeTab === tabName.doctors && (
                  <Doctors
                    status={activeTab}
                    officeId={officeId}
                    IsForDoctor={true}
                    location={location}
                  />
                )}
              </TabPane>
              <TabPane tabId={2}>
                {activeTab === tabName.patients && (
                  <Patients
                    status={activeTab}
                    officeId={officeId}
                    IsForDoctor={false}
                    getPatientListHookData={patientHookData}
                  />
                )}
              </TabPane>
            </Suspense>
          </TabContent>
        </div>
      </Card>
    </Page>
  );
};

export default withTranslation()(PMSMembers);
