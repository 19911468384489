import React, { useEffect, useState } from "react";
import Page from "components/Page";
import styles from "../../accountpreferences.module.scss";
import "../../AccountPreference.scss";
import { Card } from "reactstrap";
import Text from "components/Text";
import AttachmentIconFile from "./../../../../assets/images/attachment_ico_file.png";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { viewMessageTemplate } from "repositories/message-templates-repository";
import Loader from "components/Loader";
import { decodeId } from "utils";
import { useSelector } from "react-redux";

const ViewMessageTemplates = ({ history, t }) => {
  const profile = useSelector((state) => state?.userProfile?.profile);
  let { templateId } = useParams();
  templateId = decodeId(templateId);

  const [showLoader, setShowLoader] = useState();
  const [templateData, setTemplateData] = useState({});

  const goBack = () => {
    history.push("/message-templates");
  };

  useEffect(() => {
    getTemplateDetails();
  }, []);

  const getTemplateDetails = async () => {
    setShowLoader(true);
    try {
      let response = await viewMessageTemplate(templateId);
      const memberData = response?.data;
      setTemplateData(memberData);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const getAttachmentName = (link) => {
    return link.substring(link.lastIndexOf("/") + 1);
  };

  const renderHeader = () => (
    <div
      className={
        styles["message-templates-heading"] +
        " " +
        "d-flex justify-content-between"
      }
    >
      <p className={styles["select-office"]}>
        {t("accountPreferences.viewTemplate")}
      </p>
    </div>
  );

  const handleDownload = async (fileLink, fileName) => {
    setShowLoader(true);
    try {
      const response = await fetch(fileLink);
      const blob = await response.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const renderTemplateDetail = () => (
    <Card className={styles["message-templates-card"] + " " + "form-wrapper"}>
      <div>
        <Text
          color="#587E85"
          weight="600"
          size="16px"
          marginBottom="20px"
          className={styles["schedule-update-text"]}
        >
          {templateData?.templateName || "--"}
        </Text>
        <div>
          <div>
            <Text color="#6F7788" weight="400" size="12px">
              {t("messenger.createdBy")}
            </Text>
            <Text color="#102C42" weight="600" size="14px">
              {templateData?.creator?.id === profile?.id
                ? t("accountPreferences.you")
                : `${templateData?.creator?.firstName} ${templateData?.creator?.lastName}`}
            </Text>
          </div>
        </div>
      </div>

      <div className={styles["show-message"]}>
        <div className={styles["show-message-box"]}>
          <div>
            <Text color="#111B45" weight="600" size="16px">
              {t("accountPreferences.messageTitle")}
            </Text>
            <Text color="#535B5F" weight="300" size="16px">
              {templateData?.notificationTitle || "--"}
            </Text>
          </div>
        </div>
        <div className={styles["show-message-box"]}>
          <div>
            <Text color="#111B45" weight="600" size="16px">
              {t("accountPreferences.contentPushNotification")}
            </Text>
            <Text color="#535B5F" weight="300" size="16px">
              {templateData?.notificationContent || "--"}
            </Text>
          </div>
        </div>
        <div className={styles["show-message-box"]}>
          <div>
            <Text color="#111B45" weight="600" size="16px">
              {t("accountPreferences.contentForEmail")}
            </Text>
            {templateData?.emailContent ? (
              <Text
                color="#535B5F"
                weight="300"
                size="16px"
                dangerouslySetInnerHTML={{ __html: templateData?.emailContent }}
              >
              </Text>
            ) : (
              <Text color="#535B5F" weight="300" size="16px">
                --
              </Text>
            )}
          </div>
          {templateData?.attachmentLink && (
            <>
              <hr></hr>
              <div>
                <Text color="#111B45" weight="600" size="16px">
                  {t("accountPreferences.attachment")}
                </Text>
                <div className={styles["file-icon-box"]}>
                  <div className={styles["form-input-icon"]}>
                    <img src={AttachmentIconFile} alt="message template icon" />
                  </div>
                  <span>{getAttachmentName(templateData?.attachmentLink)}</span>
                </div>
                <button
                  className={styles["preview-btn"]}
                  onClick={() =>
                    handleDownload(
                      templateData?.attachmentLink,
                      getAttachmentName(templateData?.attachmentLink)
                    )
                  }
                >
                  {t("accountPreferences.download")}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );

  return (
    <div>
      <Page onBack={goBack} className={styles["global-main-page"]}>
        {showLoader && <Loader />}
        <div className={styles["message-templates"]}>
          {renderHeader()}
          {renderTemplateDetail()}
        </div>
      </Page>
    </div>
  );
};

export default withTranslation()(ViewMessageTemplates);
