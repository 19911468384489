import { useCallback, useEffect, useState } from "react";
import constants from "../../../../constants";
import useHandleApiError from "hooks/useHandleApiError";
import { useGetPmsMemberList } from "repositories/pms-repository";
import {
  setStorage,
  getStorage
} from "utils";
import { debounce } from "lodash";

export default function useToGetPmsDoctorsList({ tabStatus, officeId, IsForDoctor, pageSize }) {
  
  const cacheValue = getStorage(
    constants.pmsMembers.cache.pmsDoctorslisting
  );
  
  const [pmsDoctorList, setPmsDoctorList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [apiSearchText, setApiSearchText] = useState('');
  const [pageNumber, setPageNumber] = useState(cacheValue?.pageNumber || 1);

  const searchTerm = apiSearchText ? apiSearchText : "";
  const { data, isLoading, isFetching, error, refetch } =
  useGetPmsMemberList(officeId, pageNumber, pageSize, IsForDoctor, searchTerm);

  useHandleApiError(isLoading, isFetching, error);

  let totalItems = data?.pagination?.totalItems;

  useEffect(() => {
    if (pageNumber === 1) refetch();
  }, []);

  const handleSearchText = useCallback(
    debounce((searchTextValue) => {
      setPageNumber(1);
      setApiSearchText(searchTextValue);
    }, 1000),
    []
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
    handleSearchText(value);
  };
  
  useEffect(() => {
    setStorage(constants.pmsMembers.cache.pmsDoctorslisting, {
      pageNumber: pageNumber,
      status: tabStatus,
    });
  }, [pageNumber, tabStatus]);

  useEffect(() => {
    setPmsDoctorList(data?.data);
  }, [data]);

  return {
    doctorData: {
      isLoading,
      pmsDoctorList,
      totalItems,
      pageNumber, 
      searchText,
    },
    methods: {
      setPageNumber,
      handleSearch,
    },
  };
}
