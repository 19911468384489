import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Table from "components/table";
import Empty from "components/Empty";
import Page from "components/Page";
import Loader from "components/Loader";
import SortDown from "../../../../../assets/images/pms-icons/sort_down.svg";
import SortUp from "../../../../../assets/images/pms-icons/sort_up.svg";
import styles from "./pms_patient.module.scss";
import "../../pms_new.scss";
import CustomFormModal from "components/CustomFormModal";
import ToggleSwitch from "components/ToggleSwitch";
import { Tooltip } from "reactstrap";
import FamilyModal from "patient-scheduling/pages/FamilyMembers/components/AddedMembers/FamilyModal";
import CommonSearch from "components/CommonSearch";

const Patients = ({ t, getPatientListHookData }) => {
  const pageSize = 10;
  const { patientData, methods } = getPatientListHookData;
  const [sort, setSort] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPatientData, setseletedPatientData] = useState({});

  const {
    pmsPatientsList,
    isLoading,
    totalItems,
    pageNumber,
    sikkaInviteStatus,
    selectedIds,
    showLoader,
    officeDetail,
    officeId,
    notificationTooltip,
    globalNotificationToggle,
    globalNotificationStatus,
    globalNotificationModal,
    searchText,
    autoInviteStatus,
  } = patientData;
  let {globalNotification, autoInvite} = notificationTooltip;

  const {
    setPageNumber,
    toggleCheckbox,
    sendInvite,
    setSelectedIds,
    toggleAllCheckbox,
    sendMannualInvite,
    handleNotification,
    handleGlobalNotificationForOfficePreference,
    setNotificationTooltip,
    setGlobalNotificationToggle,
    setGlobalNotificationModal,
    handleSearch,
    refetchData,
    setAutoInviteToggle,
  } = methods;

  useEffect(() => {
    refetchData();
  }, []);

  const handleSikkaInviteStatus = (status, forAction) => (
    <>
      {status === sikkaInviteStatus?.new && (
        <span>
          {forAction ? t("pmsMembers.sendInvite") : t("pmsMembers.new")}
        </span>
      )}
      {status === sikkaInviteStatus?.inviteSent && (
        <span>
          {forAction
            ? t("pmsMembers.resendInvite")
            : t("pmsMembers.invitationSent")}
        </span>
      )}
    </>
  );

  const canDisableToogleAllCheckbox = (list = []) => {
    let ids = list
      .filter((val) => {
        if (val.sikkaEmailId || val?.contactNumber) {
          return true;
        }
      })
      .map((val) => val.sikkaEmailId);
    return ids.length === 0;
  };

  const columns = [
    {
      attrs: { datatitle: "counter" },
      dataField: "counter",
      text: (
        <Fragment>
          <div
            className={`ch-checkbox ${
              canDisableToogleAllCheckbox(pmsPatientsList) ? "disable-btns" : ""
            }`}
          >
            <label className="mb-0">
              <input
                type="checkbox"
                disabled={canDisableToogleAllCheckbox(pmsPatientsList)}
                checked={
                  pmsPatientsList.length > 0 &&
                  selectedIds.length === pmsPatientsList.length
                }
                onChange={(e) => toggleAllCheckbox(e)}
              />
              <span className="text-decoration-none">&nbsp;</span>
            </label>
          </div>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <div className={`flex `}>
            <div className="ch-checkbox">
              <label className="mb-0">
                <input
                  type="checkbox"
                  value={row.id}
                  checked={selectedIds.includes(row.id)}
                  onChange={(e) => toggleCheckbox(e, row)}
                />
                <span className="text-decoration-none">&nbsp;</span>
              </label>
            </div>
          </div>
        </Fragment>
      ),
    },
    {
      attrs: { datatitle: t("pmsMembers.firstName") },
      dataField: "firstName",
      text: (
        <Fragment>
          <span>
            <label className="">
              <span className="py-1 pms_table_heading">
                {" "}
                {t("pmsMembers.firstName")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <div className="d-flex">
            <div>
              <span className="pms_table_data">{row?.firstName || "--"}</span>
            </div>
          </div>
        </Fragment>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },
    {
      attrs: { datatitle: t("pmsMembers.lastName") },
      dataField: "lastName",
      text: (
        <Fragment>
          <span>
            <label className="">
              <span className="py-1 pms_table_heading">
                {" "}
                {t("pmsMembers.lastName")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span className="pms_table_data">{row?.lastName || "--"}</span>
        </Fragment>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },
    {
      attrs: { datatitle: t("pmsMembers.emailAddress") },
      dataField: "sikkaEmailId",
      text: (
        <Fragment>
          <span>
            <label className="">
              <span className="py-1 pms_table_heading">
                {t("pmsMembers.emailAddress")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span className="pms_table_data">{row.sikkaEmailId || "--"}</span>
        </Fragment>
      ),
      sort: true,
      sortCaret: (order, column) => {
        if (order === "asc" || !order)
          return (
            <span>
              <img src={SortDown} alt="sort_down" />
            </span>
          );
        else if (order === "desc")
          return (
            <span>
              <img src={SortUp} alt="sort_up" />
            </span>
          );
        return null;
      },
    },
    {
      attrs: { datatitle: t("pmsMembers.contactNumber") },
      dataField: "contactNumber",
      text: (
        <Fragment>
          <span>
            <label className="">
              <span className="py-1 pms_table_heading">
                {t("pmsMembers.contactNumber")}
              </span>
            </label>
          </span>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span className="pms_table_data">{row.contactNumber || "--"}</span>
        </Fragment>
      ),
    },
    {
      attrs: { datatitle: t("pmsMembers.status") },
      dataField: "status",
      text: (
        <Fragment>
          <div className="">
            <span>
              <label className="">
                <span className="py-1 pms_table_heading">
                  {" "}
                  {t("pmsMembers.status")}
                </span>
              </label>
            </span>
          </div>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <span>{handleSikkaInviteStatus(row?.status, false) || "--"}</span>
        </Fragment>
      ),
    },
    {
      attrs: { datatitle: t("pmsMembers.enableNotifications") },
      dataField: "enableNotifications",
      text: (
        <Fragment>
          <div className="">
            <span>
              <label className="">
                <span className="py-1 pms_table_heading">
                  {" "}
                  {t("pmsMembers.enableNotifications")}
                </span>
              </label>
            </span>
          </div>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <ToggleSwitch
            customClass={`mt-0 ${
              officeDetail?.isGlobalNotificationEnabledForNonMiraxisPatients ||
              (!row.sikkaEmailId && !row?.contactNumber)
                ? "disable-btns"
                : ""
            }`}
            label={row?.id}
            value={row?.isEnabledForGlobalNotifications}
            onChange={(e) => {
              handleNotification(
                row?.id,
                !row?.isEnabledForGlobalNotifications
              );
            }}
          />
        </Fragment>
      ),
    },
    {
      attrs: { datatitle: t("pmsMembers.action") },
      dataField: "action",
      text: (
        <Fragment>
          <div className="">
            <span>
              <label className="">
                <span className="py-1 pms_table_heading">
                  {" "}
                  {t("pmsMembers.action")}
                </span>
              </label>
            </span>
          </div>
        </Fragment>
      ),
      formatter: (cellContent, row, rowIndex) => (
        <Fragment>
          <div
            className="custom-staff-name"
            onClick={() => {
              setSelectedIds([row?.id]);
              if (
                (!row?.sikkaEmailId && !row?.miraxisInviteEmailId) ||
                !row?.contactNumber
              ) {
                setIsModalOpen(true);
                setseletedPatientData(row);
              } else {
                sendInvite([row?.sikkaUserId]);
              }
            }}
          >
            <span>{handleSikkaInviteStatus(row?.status, true)}</span>
          </div>
        </Fragment>
      ),
    },
  ];

  const handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (type === "sort") {
      setSort({ sortField, sortOrder });
    }
  };

  let updatedList = pmsPatientsList;
  if (sort && sort.sortField) {
    const { sortField, sortOrder } = sort;
    updatedList = updatedList.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      if (sortOrder === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }

  const renderListing = () => (
    <div
      className={`table-td-last-50 timesheet-table shadow-responsive ${styles["pms_doctors_table"]}`}
    >
      <div className="d-flex justify-content-end">
        <CommonSearch
          Classes="w50"
          Placeholder={t("pmsMembers.searchByPatientName")}
          HandleChange={(e) => handleSearch(e)}
          value={searchText}
        />
      </div>

      <div className="d-flex justify-content-between mt-20">
        {!!pmsPatientsList?.length && (
          <>
            <div className="d-flex justify-content-end align-items-center pb-4 ">
              <ToggleSwitch
                className="mt-0"
                label="IsGlobalNotificationEnabledForNonMiraxisPatients"
                value={globalNotificationStatus}
                onChange={(e) => {
                  setGlobalNotificationModal(true);
                  setGlobalNotificationToggle(e.target.checked);
                }}
              />
              <div className="mt-1">
                <span className={styles["globalNotificationText"]}>
                  {t("pmsMembers.globalNotificationText", {
                    statusToggle: globalNotificationStatus
                      ? "enabled"
                      : "disabled",
                  })}
                </span>
                <img
                  className="ml-2 cursor-pointer"
                  onClick={() => {
                    setNotificationTooltip({
                      ...notificationTooltip,
                      globalNotification: true});
                  }}
                  id="NotificationTooltip"
                  src={require("assets/images/info_black-tooltip.svg").default}
                  alt="icon"
                />

                <Tooltip
                  className="new-item-card-catalogue-tooltip"
                  isOpen={globalNotification}
                  placement="top"
                  target="NotificationTooltip"
                  toggle={() => {
                    setNotificationTooltip({
                      ...notificationTooltip,
                      globalNotification: !globalNotification});
                  }}
                >
                  {t("pmsMembers.globalNotificationInfoText")}
                </Tooltip>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center pb-4 ">
              <ToggleSwitch
                className="mt-0"
                customClass={`mt-0 ${
                  !officeDetail?.isGlobalNotificationEnabledForNonMiraxisPatients
                    ? "disable-btns"
                    : ""
                }`}
                label="IsAutoInviteEnabledForPatients"
                value={autoInviteStatus}
                onChange={(e) => {
                  handleGlobalNotificationForOfficePreference(
                    officeId,
                    true,
                    !officeDetail?.isAutoInviteEnabledForPatients
                  );
                  setAutoInviteToggle(e.target.checked);
                }}
              />
              <div className="mt-1">
                <span className={styles["globalNotificationText"]}>
                  {t("pmsMembers.autoInviteText", {
                    inviteStatusToggle: autoInviteStatus
                      ? "enabled"
                      : "disabled",
                  })}
                </span>
                <img
                  className="ml-2 cursor-pointer"
                  onClick={() => {
                    setNotificationTooltip({
                      ...notificationTooltip,
                      autoInvite: true});
                  }}
                  id="AutoInviteTooltip"
                  src={require("assets/images/info_black-tooltip.svg").default}
                  alt="icon"
                />

                <Tooltip
                  className="new-item-card-catalogue-tooltip"
                  isOpen={autoInvite}
                  placement="top"
                  target="AutoInviteTooltip"
                  toggle={() => {
                    setNotificationTooltip({
                      ...notificationTooltip,
                      autoInvite: !autoInvite});
                  }}
                >
                  {t("pmsMembers.autoInviteInfoText")}
                </Tooltip>
              </div>
            </div>
          </>
        )}
      </div>
      {!!pmsPatientsList?.length ? (
        <>
          <Table
            keyField="id"
            data={updatedList}
            columns={columns}
            handlePagination={(e) => setPageNumber(e)}
            pageNumber={pageNumber}
            defaultSortDirection={"desc"}
            totalItems={totalItems}
            pageSize={pageSize}
            onTableChange={handleTableChange}
          />
        </>
      ) : (
        <Empty Message={t("noRecordFound")} />
      )}
    </div>
  );

  return (
    <Page className={"staff-listing-timesheet pt-1"}>
      {(isLoading || showLoader) && <Loader />}
      {renderListing()}
      {isModalOpen && (
        <CustomFormModal
          isOpen={isModalOpen}
          setIsOpen={() => {
            setIsModalOpen(false);
          }}
          title={t("pmsMembers.sendInvite")}
          modalData={selectedPatientData}
          leftBtnText={t("pmsMembers.sendInvite")}
          rightBtnText={t("cancel")}
          onConfirm={(selectedEmailId, selectedContactNumber) => {
            setSelectedIds([selectedPatientData?.id]);
            sendMannualInvite(
              selectedEmailId,
              selectedPatientData?.sikkaUserId,
              selectedContactNumber
            );
            setIsModalOpen(false);
          }}
        />
      )}
      {globalNotificationModal && (
        <FamilyModal
          isFamilyModalOpen={globalNotificationModal}
          setIsFamilyModalOpen={() => setGlobalNotificationModal(false)}
          title={
            globalNotificationToggle
              ? t("pmsMembers.enableNotificationsTitle")
              : t("pmsMembers.disableNotificationsTitle")
          }
          subTitle1={
            globalNotificationToggle
              ? t("pmsMembers.enableNotificationsSubtitle1")
              : t("pmsMembers.disableNotificationsSubtitle1")
          }
          subTitle2={
            globalNotificationToggle
              ? t("pmsMembers.enableNotificationsSubtitle2")
              : null
          }
          leftBtnText={
            globalNotificationToggle
              ? t("pmsMembers.enableLeftBtn")
              : t("pmsMembers.disableLeftBtn")
          }
          rightBtnText={t("cancel")}
          onConfirm={() => {
            handleGlobalNotificationForOfficePreference(
              officeId,
              !officeDetail?.isGlobalNotificationEnabledForNonMiraxisPatients,
              false
            );
            setGlobalNotificationModal(false);
          }}
        />
      )}
    </Page>
  );
};

export default withTranslation()(Patients);
