import React, { useEffect, useState } from "react";
import Page from "components/Page";
import styles from "../../accountpreferences.module.scss";
import "../../AccountPreference.scss";
import { Button, Card } from "reactstrap";
import ChatIcon from "./../../../../assets/images/chat-input-icon.svg";
import AttachmentIconFile from "./../../../../assets/images/attachment_ico_file.png";
import deleteIcon from "./../../../../assets/images/delete-icon.svg";
import Input from "components/Input";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import Editor from "react-pell";
import colorPicker from "./../../../../assets/images/color-picker.svg";
import PlaceholdersModal from "pages/AccountPreferences/modals/PlaceholdersModal";
import PreviewModal from "pages/AccountPreferences/modals/PreviewModal";
import toast from "react-hot-toast";
import useUploadService from "hooks/useUploadService";
import { decodeId, getBlobnameFromUrl } from "utils";
import { useSelector } from "react-redux";
import {
  addMessageTemplate,
  updateMessageTemplate,
  viewMessageTemplate,
} from "repositories/message-templates-repository";
import constants from "./../../../../constants";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";

let scheme = {
  templateName: "",
  notificationTitle: "",
  notificationContent: "",
  emailContent: "",
  attachmentLink: "",
};

const ManageMessageTemplates = ({ history, t }) => {
  const CustomInputClass = "global-preference-custom-input";
  const profile = useSelector((state) => state?.userProfile?.profile);
  const isAccountOwner =
  profile?.role?.systemRole === constants.systemRoles.accountOwner;
  const _selectedOwner = JSON.parse(localStorage.getItem("selectedOwner"));

  const accept = constants.chat.acceptForMessageInput;
  const allowedTypes = constants.chat.allowedTypesForMessage;
  let { templateId } = useParams();
  templateId = decodeId(templateId);

  const [formFields, setformFields] = useState(scheme);
  const [errors, setErrors] = useState({});
  const [showPlaceholderModal, setShowPlaceholderModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isPreviewForEmailContent, setIsPreviewForEmailContent] = useState(false);
  const [placeholderSelectedFor, setPlaceholderSelectedFor] = useState("");
  const [contentForPreview, setContentForPreview] = useState({
    content: "",
    title: "",
  });
  const [attachmentFile, setAttachmentFile] = useState({
    path: "",
    file: null,
  });
  const { upload: uploadImage } = useUploadService();

  const { templateName, notificationTitle, notificationContent, emailContent } =
    formFields;

  const containerName = "globalnotesattachments";

  useEffect(() => {
    if (templateId) {
      getMessageTemplateDetails();
    }
  }, []);

  const getMessageTemplateDetails = async () => {
    setShowLoader(true);
    try {
      let response = await viewMessageTemplate(templateId);
      const templateData = response?.data;

      if (templateData) {
        setformFields(templateData);

        if (templateData.attachmentLink) {
          setAttachmentFile({
            path: templateData.attachmentLink,
            file: null,
          });
        }
      }
    } catch (error) {
      toast.error(error.message);
    }

    setShowLoader(false);
  };

  const goBack = () => {
    history.push(constants.routes.accountPreference.messageTemplates);
  };

  const errorMessage = {
    templateName: t("form.errors.emptyField", {
      field: t("accountPreferences.templateName"),
    }),
    notificationTitle: t("form.errors.emptyField", {
      field: t("accountPreferences.messageTitle"),
    }),
    notificationContent: t("form.errors.emptyField", {
      field: t("accountPreferences.contentPushNotification"),
    }),
    emailContent: t("form.errors.emptyField", {
      field: t("accountPreferences.contentForEmail"),
    }),
  };

  const handleChange = (event) => {
    const errorCopy = cloneDeep(errors);
    const { name, value } = event.target;

    if (!value.trim()?.length) {
      errorCopy[name] = errorMessage[name];
    }

    if (value?.trim()?.length) {
      delete errorCopy[name];
    }

    setformFields((prevProps) => ({ ...prevProps, [name]: value }));
    setErrors(errorCopy);
  };

  const handleEditorChnage = (value) => {
    handleChange({ target: { name: "emailContent", value: value } });
  };

  const handlePlaceholderValue = (value) => {
    switch (placeholderSelectedFor) {
      case t("accountPreferences.messageTitle"):
        setformFields({
          ...formFields,
          notificationTitle: `${notificationTitle} ${value}`,
        });
        break;

      case t("accountPreferences.contentPushNotification"):
        setformFields({
          ...formFields,
          notificationContent: `${notificationContent} ${value}`,
        });
        break;

      case t("accountPreferences.contentForEmail"):
        setformFields({
          ...formFields,
          emailContent: `${emailContent} ${value}`,
        });
        break;

      default:
        break;
    }
  };

  const handlePreview = (previewValue, previewName) => {
    setContentForPreview({
      content: previewValue,
      title: previewName,
    });
  };

  const getAttchmentFile = (event) => {
    if (!event || !event.target) return;

    const files = event.target.files;
    if (!files.length) return;

    if (!allowedTypes.includes(files[0]?.type)) {
      toast.error(t("fileNotSupported"));
      return;
    }

    const fileSizeInBytes = files[0]?.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);

    if (fileSizeInMB > 5) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        attachmentLink: t("form.errors.fileSize"),
      }));
      return false;
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        attachmentLink: "",
      }));

      setAttachmentFile({
        path: URL.createObjectURL(files[0]),
        file: files[0],
      });
    }
  };

  const removeAttachment = () => {
    setAttachmentFile({
      path: "",
      file: null,
    });
  };

  const isValid = () => {

    const errorCopy = {};
    let isValidField = true;

    if (!templateName?.trim()?.length) {
      errorCopy.templateName = errorMessage.templateName;
      isValidField = false;
    }

    if (!notificationTitle?.trim()?.length) {
      errorCopy.notificationTitle = errorMessage.notificationTitle;
      isValidField = false;
    }

    if (!notificationContent?.trim()?.length) {
      errorCopy.notificationContent = errorMessage.notificationContent;
      isValidField = false;
    }

    if (!emailContent?.trim()?.length) {
      errorCopy.emailContent = errorMessage.emailContent;
      isValidField = false;
    }

    setErrors({ ...errorCopy });
    return isValidField;
  };


  const saveMessageTemplate = async () => {
    if (!isValid()) return;
    setShowLoader(true);

    try {
      let imageBlobName;
      if (attachmentFile && attachmentFile.file && attachmentFile.path) {
        const [err, blobData] = await uploadImage(
          attachmentFile.file,
          containerName
        );

        let fileName = blobData.blobUrl;

        imageBlobName = `${containerName}/${blobData.blobName}`;

        if (err) {
          throw new Error(err);
        }

        setAttachmentFile({
          path: fileName,
          file: null,
        });
      }

      if (attachmentFile && attachmentFile.path && !attachmentFile.file) {
        imageBlobName = `${containerName}/${getBlobnameFromUrl(
          attachmentFile.path,
          containerName
        )}`;
      }

      const params = {
        ownerId: isAccountOwner ? profile?.id : _selectedOwner?.id,
        templateName,
        notificationTitle,
        notificationContent,
        emailContent,
        attachmentLink: imageBlobName,
      };

      let res = templateId
        ? await updateMessageTemplate({
            ...params,
            messageTemplateId: +templateId,
          })
        : await addMessageTemplate(params);
      toast.success(res.message);
      history.push(constants.routes.accountPreference.messageTemplates);
    } catch (err) {
      toast.error(err.message);
    }

    setShowLoader(false);
  };

  const getAttachmentName = (link) => {
    return link.substring(link.lastIndexOf("/") + 1);
  };

  const renderHeader = () => (
    <div
      className={
        styles["message-templates-heading"] +
        " " +
        "d-flex justify-content-between"
      }
    >
      <p className={styles["select-office"]}>
        {templateId ? t("accountPreferences.editTemplate") : t("accountPreferences.newTemplate")}
      </p>
    </div>
  );

  const renderFooter = () => (
    <div className={styles["btn-container"]}>
      <Button
        className="button button-round button-shadow mr-md-4 mb-3 w-sm-100"
        onClick={() => saveMessageTemplate()}
      >
        {t("Save")}
      </Button>
      <Button
        className="button button-round button-border button-dark btn-mobile-link "
        onClick={() => goBack()}
      >
        {t("cancel")}
      </Button>
    </div>
  );

  const renderTemplateForm = () => (
    <Card className={styles["global-preference-form"] + " " + "form-wrapper"}>
      <div className={styles["message-form-container"]}>
        <div>
          <Input
            Title={t("accountPreferences.templateName")}
            Type="text"
            Name={"templateName"}
            Placeholder={t("accountPreferences.templateName")}
            Error={errors.templateName}
            Value={templateName}
            HandleChange={handleChange}
            MaxLength={120}
          />
        </div>
        <div className={styles["message-form"]}>
          <div className={styles["form-input-box"]}>
            {t("accountPreferences.messageTitle")}
            <div className={styles["form-icon-box"]}>
              <div
                className={styles["form-input-icon"] + " " + "cursor-pointer"}
                onClick={() => {
                  setPlaceholderSelectedFor(
                    t("accountPreferences.messageTitle")
                  );
                  setShowPlaceholderModal(true);
                }}
              >
                <img src={ChatIcon} alt="message template icon" />
              </div>
              <button
                className={styles["preview-btn"]}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPreview(true);
                  handlePreview(
                    notificationTitle,
                    t("accountPreferences.previewTitleSubject")
                  );
                }}
              >
                {t("accountPreferences.preview")}
              </button>
            </div>
            <Input
              CustomClass={CustomInputClass}
              Type="text"
              Name={"notificationTitle"}
              Placeholder={t("accountPreferences.messageTitle")}
              Error={errors.notificationTitle}
              Value={notificationTitle}
              HandleChange={handleChange}
              MaxLength={120}
            />
          </div>
          <div className={styles["form-input-box"]}>
            {t("accountPreferences.contentPushNotification")}
            <div className={styles["form-icon-box"]}>
              <div
                className={styles["form-input-icon"] + " " + "cursor-pointer"}
                onClick={() => {
                  setPlaceholderSelectedFor(
                    t("accountPreferences.contentPushNotification")
                  );
                  setShowPlaceholderModal(true);
                }}
              >
                <img src={ChatIcon} alt="message template icon" />
              </div>
              <button
                className={styles["preview-btn"]}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPreview(true);
                  handlePreview(
                    notificationContent,
                    t("accountPreferences.previewForNotification")
                  );
                }}
              >
                {t("accountPreferences.preview")}
              </button>
            </div>
            <Input
              CustomClass={CustomInputClass}
              Type="text"
              Name={"notificationContent"}
              Placeholder={t("accountPreferences.contentPushNotification")}
              Error={errors.notificationContent}
              Value={notificationContent}
              HandleChange={handleChange}
              MaxLength={240}
            />
          </div>
          <div className={styles["form-input-box"]}>
            {t("accountPreferences.contentForEmail")}
            <div className={"text-right"}>
              <button
                className={styles["preview-btn"]}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPreview(true);
                  setIsPreviewForEmailContent(true);
                  handlePreview(
                    emailContent,
                    t("accountPreferences.previewForEmail")
                  );
                }}
              >
                {t("accountPreferences.preview")}
              </button>
            </div>
            <Editor
              defaultContent={emailContent}
              actions={[
                "bold",
                "italic",
                "underline",
                "link",
                {
                  icon: `<span> <img src=${colorPicker} alt= "picker" /> <input type="color" class="color-picker-input" oninput="document.execCommand('styleWithCSS', true, null);document.execCommand('foreColor', false, this.value); " /></span>`,
                  title: "Change Text Color",
                  result: () => true,
                },
                {
                  icon: `<span> <img src=${ChatIcon} alt="message template icon" /></span>`,
                  title: "Placeholder",
                  result: () => {
                    setPlaceholderSelectedFor(
                      t("accountPreferences.contentForEmail")
                    );
                    setShowPlaceholderModal(true);
                  },
                },
              ]}
              actionBarClass="my-custom-class"
              onChange={handleEditorChnage}
            />
            {errors.emailContent && (
              <span className="error-msg">{errors.emailContent}</span>
            )}
          </div>

          <div className={styles["file-input-container"]}>
            <span> {t("accountPreferences.attachment")}</span>

            <div className={styles["file-icon-box"]}>
              <div className={styles["form-input-icon"]}>
                <img src={AttachmentIconFile} alt="message template icon" />
              </div>
              {!templateId ? (
                <>
                  {attachmentFile?.file?.name ? (
                    <div className="attachemnt-container">
                      <span>{attachmentFile?.file?.name}</span>
                      <span
                        className="cursor-pointer"
                        onClick={() => removeAttachment()}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </span>
                    </div>
                  ) : (
                    <span>{t("accountPreferences.noAttachment")}</span>
                  )}
                </>
              ) : (
                <>
                  {attachmentFile?.file?.name || attachmentFile?.path ? (
                    <div className="attachemnt-container">
                      <span>
                        {attachmentFile?.file?.name
                          ? attachmentFile?.file?.name
                          : getAttachmentName(attachmentFile?.path)}
                      </span>
                      <span
                        className="cursor-pointer"
                        onClick={() => removeAttachment()}
                      >
                        <img src={deleteIcon} alt="delete" />
                      </span>
                    </div>
                  ) : (
                    <span>{t("accountPreferences.noAttachment")}</span>
                  )}
                </>
              )}
            </div>
            {((!templateId && !attachmentFile?.file?.name) ||
              (templateId && !attachmentFile?.path)) && (
              <label
                htmlFor="fileUpload"
                className={styles["attach-file-btn"] + " " + "cursor-pointer"}
              >
                {t("accountPreferences.attachFile")}
              </label>
            )}
            <input
              id="fileUpload"
              className={styles["file-input-box"]}
              type="file"
              accept={accept}
              onChange={getAttchmentFile}
              multiple={false} // Set to true if you want to allow selecting multiple files
            />
          </div>
          {errors?.attachmentLink && (
            <span className="error-msg">{errors.attachmentLink}</span>
          )}
        </div>
      </div>
      {renderFooter()}
    </Card>
  );

  return (
    <div>
      <Page onBack={goBack} className={styles["global-main-page"]}>
        {showLoader && <Loader />}
        <div className={styles["message-templates"]}>
          {renderHeader()}
          {renderTemplateForm()}
        </div>
        {showPlaceholderModal && (
          <PlaceholdersModal
            subTitle={t("accountPreferences.template")}
            showPlaceholderModal={showPlaceholderModal}
            setShowPlaceholderModal={setShowPlaceholderModal}
            handleSelectedPlaceholder={(selectedPlaceholder) => {
              handlePlaceholderValue(selectedPlaceholder);
            }}
          />
        )}
        {showPreview && (
          <PreviewModal
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            title={t("accountPreferences.previewModalTitle", {
              previewFor: contentForPreview.title,
            })}
            messageTitle={notificationTitle}
            content={contentForPreview.content}
            isPreviewForEmailContent={isPreviewForEmailContent}
            setIsPreviewForEmailContent={setIsPreviewForEmailContent}
            btnText={t("accountPreferences.done")}
          />
        )}
      </Page>
    </div>
  );
};

export default withTranslation()(ManageMessageTemplates);
