import Page from 'components/Page';
import React, { useState, useEffect, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import styles from "./Scheduler.module.scss";
import "./Scheduler.scss";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Card from 'components/Card';

import SwitchOwnerModal from './components/Modals/SwitchOwnerModal';
import { useAccountOwners } from 'repositories/scheduler-repository';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';
import constants from '../../../constants';
import { sortBy } from 'lodash';
import useAgendaFilter from 'hooks/useAgendaFilter';
import useSubscriptionAccess from 'hooks/useSubscriptionAccess'
import useStaffAvailabilityFilter from 'hooks/useStaffAvailabilityFilter';
import { handleError } from 'utils';
import { useHistory } from 'react-router-dom';

const YourScheduleTab = React.lazy(() => import('././YourScheduleTab'));
const OfficeSchedulerTab = React.lazy(() => import('./OfficeSchedulerTab'));

const Scheduler = ({ t }) => {
    const selectTabs = localStorage.getItem('selectTabs') ? JSON.parse(localStorage.getItem('selectTabs')) : { tab: '1', subTab: '1' };
    const [subTabs, setSubTabs] = useState(selectTabs)
    const modulesAccess = useSelector(prev => prev)
    const { isModuleDisabledClass, isModuleDisabledClassForStaff } = useSubscriptionAccess()
    const profile = useSelector(state => state.userProfile.profile)
    const history = useHistory()

    useEffect(() => {
        localStorage.setItem('selectTabs', JSON.stringify(subTabs))
    }, [subTabs, selectTabs])

    let isStaff = false;
    if (profile && profile.role) {
        isStaff = profile.role.systemRole === constants.systemRoles.staff
        if (!isStaff) localStorage.removeItem('selectedOwner')
    }



    const [activeTab, setActiveTab] = useState(selectTabs.tab);
    const agendaFiltersData = useAgendaFilter();
    const staffAvailFiltersData = useStaffAvailabilityFilter();



    useEffect(() => {
        let subTab = selectTabs.subTab;
        if (activeTab !== selectTabs.tab) subTab = '1';
        if (subTab == '3' && activeTab == '1') subTab = '1';
        setSubTabs({ tab: activeTab, subTab })
        // eslint-disable-next-line
    }, [activeTab])


    const sortedOwnerList = (ownerList) => {
        return sortBy(ownerList, [owner => owner.firstName?.toLowerCase()]);
    }

    const [showSwitchOwnerModal, setShowSwitchOwnerModal] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const { isLoading: loadinOwnerData, data: ownerData } = useAccountOwners();
    const switchNewAccountOwner = (owner) => {
        if (owner) {
            setSelectedOwner(owner);
            localStorage.setItem('selectedOwner', JSON.stringify(owner));
        }
    }

    useEffect(() => {

        if (modulesAccess && ownerData) {
            if (isStaff) {
                getStaffHaveModuleAccess();
            } else {
                if (isModuleDisabledClass(constants.moduleNameWithId.scheduler)) {
                    window.location.href = "/"
                }
            }
        }
    }, [modulesAccess])

    const getStaffHaveModuleAccess = () => {

        try {

            const subscription = modulesAccess?.Subscription;
            let accessibleModule;
            if (subscription?.length) {
                for (let i = 0; i < subscription.length; i++) {
                    const val = subscription[i];

                    if (val.planFeature) {
                        accessibleModule = val?.planFeature?.find((plan) => (plan?.id === constants.moduleNameWithId.scheduler) && plan?.isAvailable)
                    } else {
                        accessibleModule = val;
                    }

                    if (accessibleModule) {
                        accessibleModule.ownerId = val?.ownerId;
                        break; // Break out of the loop
                    }
                }

                if (isModuleDisabledClassForStaff(constants.moduleNameWithId.scheduler)) {
                    window.location.href = "/"
                    return
                }


                let arrayOfAvalibleOwner = []
                if (ownerData?.length) {
                    ownerData?.forEach(item => {
                        subscription?.forEach(list => {
                            if (item.id === list.ownerId) {
                                arrayOfAvalibleOwner.push(item)
                            }
                        });
                    });
                }



                let _selectedOwner = JSON.parse(localStorage.getItem('selectedOwner'));
                if (_selectedOwner) {
                    const isSelectedOwnerExist = arrayOfAvalibleOwner.find(item => item.id === _selectedOwner.id)
                    if (isSelectedOwnerExist) {
                        setSelectedOwner(isSelectedOwnerExist);
                        localStorage.setItem('selectedOwner', JSON.stringify(isSelectedOwnerExist));
                    } else {
                        if (!arrayOfAvalibleOwner?.length) return null
                        setSelectedOwner(arrayOfAvalibleOwner[0]);
                        localStorage.setItem('selectedOwner', JSON.stringify(arrayOfAvalibleOwner[0]));
                    }

                }
                else {
                    if (!arrayOfAvalibleOwner?.length) return null
                    setSelectedOwner(arrayOfAvalibleOwner[0]);
                    localStorage.setItem('selectedOwner', JSON.stringify(arrayOfAvalibleOwner[0]));
                }
            } else {
                history.push('/')
            }

        } catch (error) {
            handleError(error);
        }
    };

    const goToPreviousScreen = () => {
        history.push("/");
    };


    return (
        <Page onBack={goToPreviousScreen} className="scheduler-page">
            {(loadinOwnerData) && <Loader />}

            <div className="d-md-flex justify-content-between mb-4">
                <h2 className="page-title">{t("accountOwner.scheduler")}</h2>
                {isStaff && <div className="top-right-text">
                    {selectedOwner && <div className="show-text">{t('staff.showingSchedule')} <b>'{selectedOwner.firstName} {selectedOwner.lastName}'</b></div>}
                    {selectedOwner && <span className="link-btn" onClick={() => { setShowSwitchOwnerModal(true) }}>Change</span>}
                </div>}
            </div>
            <Card className={styles["scheduler-card"]}>
                <div className='common-tabs scheduler-tabs'>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={activeTab == '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                {t('accountOwner.officeScheduler')}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab == '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                {t('accountOwner.mySchedule')}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <Suspense fallback={<Loader />} >
                            <TabPane tabId='1'>
                                {activeTab === '1' &&
                                    <OfficeSchedulerTab
                                        subTabed={subTabs}
                                        setSubTabed={setSubTabs}
                                        staffAvailFiltersData={staffAvailFiltersData}
                                    />
                                }
                            </TabPane>
                            <TabPane tabId='2'>
                                {activeTab === '2' &&
                                    <YourScheduleTab
                                        subTabed={subTabs}
                                        setSubTabed={setSubTabs}
                                        agendaFiltersData={agendaFiltersData}
                                    />
                                }


                            </TabPane>
                        </Suspense>
                    </TabContent>
                </div>
            </Card>

            {SwitchOwnerModal && (
                <SwitchOwnerModal
                    subTitle={t("accountOwner.scheduler")}
                    showSwitchOwnerModal={showSwitchOwnerModal}
                    setShowSwitchOwnerModal={setShowSwitchOwnerModal}
                    ownerList={sortedOwnerList(ownerData || [])}
                    selectedOwner={selectedOwner}
                    setSelectedOwner={switchNewAccountOwner}
                    resetAgendaFilters={agendaFiltersData.resetFilters}
                    resetStaffAvailFilters={staffAvailFiltersData.resetFilters}
                />
            )}
        </Page>
    );
};

export default withTranslation()(Scheduler);