import React,{useEffect} from "react";
import styles from "./style.module.scss";
import EventsBanner from "../../assets/images/landing-pages/HeroImage.jpg";
import UpcomingCard from "./EventsCard/EventsCard";
import HomeContact from "components/Home-contact";
import LatestCard from "./EventsCard/LatestCard";
import PastCard from "./EventsCard/PastCard";
import { UpcomingEvent, PastEvent } from "./EventData";
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyInformation } from 'actions';

export default function EventsPage() {

  const dispatch = useDispatch();
  const companyInfo = useSelector(state => state.pageContent.companyInformation);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCompanyInformation());
  }, [dispatch]);

  return (
    <>
      <div className={styles["events-page"] + " " + "events-section"} id="events-section">
        <div className={styles["banner-container"]}>
          <img
            src={EventsBanner}
            alt="img"
            className={styles["events-banner"]}
          />
          <LatestCard />
        </div>

        <div className={styles["card-container"]}>
          <div
            className={
              UpcomingEvent.length > 0
                ? styles["upcoming-card-container"]
                : null
            }
          >
            {UpcomingEvent?.map((item, index) => (
              <UpcomingCard
                key={index}
                icon={item.iconPath}
                eventHeading={item.eventHeading}
                subHeading={item?.subHeading}
                link={item.link}
                image={item.imagePath}
                date={item.date}
                booth={item.text}
              />
            ))}
          </div>
          <div className={styles["past-card-container"]}>
            <div className={styles["past-card-heading"]}>
              <span>Past Events</span>
            </div>
            <div>
              {PastEvent.map((item, index) => (
                <PastCard
                  key={index}
                  icon={item.iconPath}
                  eventHeading={item.eventHeading}
                  link={item.link}
                  image={item.imagePath}
                  date={item.date}
                  booth={item.text}
                />
              ))}
            </div>
          </div>
        </div>
        <HomeContact companyInformation={companyInfo} />
      </div>
    </>
  );
}
