import React, { Fragment, useMemo } from "react";
import {
  useToGetListOfSubscriptionFeaturesOfOwner,
} from "repositories/subscription-repository";
import { useSelector } from "react-redux";
import useHandleApiError from "hooks/useHandleApiError";
import Page from "components/Page";
import Card from "components/Card";
import { withTranslation } from "react-i18next";
import constants, { getsubcriptionPlanTitle } from "../../../constants";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap/lib";
import Empty from "components/Empty";
import Loader from "components/Loader";
import styles from "./ViewFeature.module.scss";
import alertLogo from "../../../assets/images/alert-circle-black.svg";

function View({ t }) {
  const profile = useSelector((e) => e.userProfile.profile);
  const history = useHistory();
  const isTrial = profile?.userSubscription?.subscriptionPlan ===
    constants.subscriptionType.trial;

 
  const { data, isLoading, isFetching, error } =
    useToGetListOfSubscriptionFeaturesOfOwner(profile?.id, {
      enabled: profile?.userSubscription?.subscriptionPlan ? true : false ,
    });
  useHandleApiError(isLoading, isFetching, error);

  const subscriptionData = useMemo(() => {
    if (data?.planFeature?.length) {
      const category = [
        {
          categoryId: 1,
          moduleFeature: [],
          categoryName: t("userPages.plan.officStaffManagement"),
        },
        {
          categoryId: 2,
          moduleFeature: [],
          categoryName: t("userPages.plan.StaffManagementCollaboration"),
        },
        {
          categoryId: 3,
          moduleFeature: [],
          categoryName: t("userPages.plan.virtualHr"),
        },
        {
          categoryId: 4,
          moduleFeature: [],
          categoryName: t("userPages.plan.doctorsCollaboration"),
        },
        {
          categoryId: 5,
          moduleFeature: [],
          categoryName: t("userPages.plan.patientEngagement"),
        },
        {
          categoryId: 6,
          moduleFeature: [],
          categoryName: t("userPages.plan.supplyManagement"),
        },
        {
          categoryId: 7,
          moduleFeature: [],
          categoryName: t("userPages.plan.supportTraining"),
        },
      ];
      data?.planFeature.forEach((element) => {
        category.forEach((list, index) => {
          if (element.category === list.categoryId && element.isAvailable) {
            category[index].moduleFeature.push(element);
          }
        });
      });
      return category;
    }
    return [];
  }, [data?.planFeature]);

  let featuresView = null;
   
    featuresView = (
      <Fragment>
        {subscriptionData.length > 0 ? (
          subscriptionData.map((item) => {
            if (!item?.moduleFeature?.length) return null;

            return (
              <Fragment key={item?.id}>
                <div className={styles["card-interior-heading"]}>
                  {item?.categoryName}
                </div>
                <Row>
                  {item?.moduleFeature?.length &&
                    item.moduleFeature.map((list) => {
                      return (
                        <Col
                          lg="6"
                          key={list?.id}
                          className={styles["card-col"]}
                        >
                          <span className={styles["card-data"]}>
                            <img
                              className={styles["check-img"]}
                              src={
                                require("assets/images/heavy-check.svg").default
                              }
                              alt="icon"
                            />
                            <div className={styles["main-data"]}>
                              {list?.name}
                            </div>
                          </span>
                        </Col>
                      );
                    })}
                </Row>
              </Fragment>
            );
          })
        ) : (
          <Empty Message={t("noRecordFound")} />
        )}
      </Fragment>
    );
  

  return (
    <Page
      className={styles["subscriptionDataPage"]}
      title={t("features")}
      onBack={() => history.push(constants.routes.accountOwner.managePlan)}
    >
      {isLoading && <Loader />}
      <Card className={styles["subscriptionDataCard1"]}>
        <div className={styles["top-div"]}>
          <div className={styles["top-div-logo"]}>
            <img src={alertLogo} alt="icon" />
          </div>
          <div className={styles["top-div-para"]}>
            {t("userPages.currentrlUsingProffessionalText", {
              field: getsubcriptionPlanTitle(
                isTrial
                  ? constants.subscriptionType.trial
                  : data?.subscriptionPlan
              ),
            })}
          </div>
        </div>

        <Card className={styles["subscriptionDataCard2"]}>
          <div className={styles["card-title"]}>
            {t("userPages.featuresAddediInProfessionalPlan", {
              field: getsubcriptionPlanTitle(
                isTrial
                  ? constants.subscriptionType.trial
                  : data?.subscriptionPlan
              ),
            })}
          </div>
          <div className={styles["line"]}></div>
          {featuresView}
        </Card>
      </Card>
    </Page>
  );
}
export default withTranslation()(View);
